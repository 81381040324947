
export {listas}

const antojitos_Mexicanos = []//Posicion 0
const pizzerias = []//Posicion 1
const cafeterias = []//Posicion 2
const marisquerias = []//Posicion 3
const taquerias = []//Posicion 4
const brunchs = []//Posicion 5
const hamburgeserias = []//Posicion 6
const cortes_restauante = []//Posicion 7
const sushis = []//Posicion 8
const hamburgeseriasYhotdogs = []//Posicion 9
const cocina_Oriental = []//Posicion 10
const bbqY_ahumador = []//Posicion 11
const tortas_Ahogadas = []//Posicion 12
const menuderias = []//Posicion 13
const hotDogs = []//Posicion 14
const gusguerias = []//Posicion 15
const alitas = []//Posicion 16
const panaderias = []//Posicion 17
const bares = []//Posicion 18
const nieves = []//Posicion 19
const loncheria = []//Posicion 20
const munchies = []//Posicion 21
const comida_Argentina = []//Posicion 22
const comida_Italiana= []//Posicion 23
const hoteles = []//Posicion 24
const dulceria = []//Posicion 25
const cocina_Autor =[]//Posicion 26
const postres =[]//Posicion 27
const comida_Thailandesa = []//Posicion 28
const comida_Francesa = []//Posicion 29
const chocolateria = []//Posicion 30
const polleria = []//Posicion 31
const campestre = []//Posicion 32
const cocina_Internacional = []//Posicion 33
const cocina_Inglesa = []//Posicion 33
const listas = [antojitos_Mexicanos,pizzerias,cafeterias,marisquerias,taquerias,brunchs,hamburgeserias,cortes_restauante,sushis,hamburgeseriasYhotdogs,cocina_Oriental,bbqY_ahumador,tortas_Ahogadas,menuderias,hotDogs,gusguerias,alitas, panaderias,bares,nieves,loncheria,munchies,comida_Argentina,comida_Italiana,hoteles, dulceria,cocina_Autor,postres,comida_Thailandesa, comida_Francesa, chocolateria, polleria,campestre,cocina_Internacional,cocina_Inglesa]

antojitos_Mexicanos.push(L.marker([20.6575035526040, -103.3439087521045], {
    nombre: "Gallo y toro",
    direccion : "Obrera, C. Pedro Moreno 1449, Col Americana",
    ticket: 220,
    link:"https://www.instagram.com/p/CaGN5vyOhfb/"
}));

cafeterias.push(L.marker([20.675532851484427, -103.36712420501716], {
    nombre: "Café Pía",
    direccion : "Av Naciones Unidas 6780-local 4",
    ticket: 320,
    link:"https://www.instagram.com/p/CbYsx6fl87J/"
}));

brunchs.push(L.marker([20.738891386637537, -103.3138941743286], {
    nombre: "San Mateo Restaurante",
    direccion : "Volcán Zacapu 138, Huentitán El Bajo",
    ticket: 150,
    link:"https://www.instagram.com/p/CcDq_ODlM5v/"
}));

hamburgeserias.push(L.marker([20.708691558342494, -103.40645826268799], {
    nombre: "Sham Burgers",
    direccion : "Av. Acueducto 4295-B, Colinas de San Javier",
    ticket: 250,
    link:"https://www.instagram.com/p/CcydLVflQfA/"
}));

marisquerias.push(L.marker([20.665379043990875, -103.43179586083829], {
    nombre: "Aguachile pa la cruda",
    direccion : "Av Rafael Sanzio 598, Arcos de Guadalupe",
    ticket: 250,
    link:"https://www.instagram.com/p/Cc5trR4l-Ki/"
}));

pizzerias.push(L.marker([20.68806082132966, -103.39559614734515], {
    nombre: "Mutz Pizza",
    direccion : "Calle Gral. Eulogio Parra 3200, Prados Providencia",
    ticket: 300,
    link:"https://www.instagram.com/p/CdT3VNsl0lT/"
}));

pizzerias.push(L.marker([20.686685408757516, -103.38718184734516], {
    nombre: "Bocazza pizza & chela",
    direccion : "C. José Bonifacio Andrada 2861, Prados Providencia",
    ticket: 200,
    link:"https://www.instagram.com/p/Cdoh0LqlCsk/"
}));

taquerias.push(L.marker([20.699434724896307, -103.30165230501653], {
    nombre: "Taquería el pariente",
    direccion : "Av. Aviación 4368, Jardín Real",
    ticket: 200,
    link:"https://www.instagram.com/p/Cdv6oxEFoLy/"
}));

cafeterias.push(L.marker([20.668083747763422, -103.43578476083817], {
    nombre: "Lolo café",
    direccion : "45110, C. Robert Schumann 5697B, La Estancia",
    ticket: 180,
    link:"https://www.instagram.com/p/CecTah0le4s/"
}));

marisquerias.push(L.marker([20.68848368376948, -103.42658057618101], {
    nombre: "Los salseados de Mazatlán",
    direccion : "Av Naciones Unidas 5484, Vallarta Universidad",
    ticket: 350,
    link:"https://www.instagram.com/p/Cewf7exlH8j/"
}))

hamburgeserias.push(L.marker([20.730603401808228, -103.45142064734392], {
    nombre: "Chirimbola",
    direccion : "Esquina con, Av. Santa Margarita, Av. Aviación 3700, San Juan de Ocotán",
    ticket: 250,
    link:"https://www.instagram.com/p/CfRwVToFt6B/"
}))

cafeterias.push(L.marker([20.674367549787913, -103.45124969152482], {
    nombre: "Guter Coffe bread",
    direccion : "Calz de los Angeles 6945, Granja",
    ticket: 100,
    link:"https://www.instagram.com/p/CfrhOU4l-Lf/"
}))

cortes_restauante.push(L.marker([20.700026351842485, -103.38317636268822], {
    nombre: "Pachamama",
    direccion : "Av. Rubén Darío, Providencia 4a. Secc",
    ticket: 500 ,
    link:"https://www.instagram.com/p/Cfwp5lqFlWV/"
}))
taquerias.push(L.marker([20.665193105754433, -103.43697773385337], {
    nombre: "Los Compios",
    direccion : "Av. Piotr Ilich Tchaikovski 578, Arcos de Guadalupe",
    ticket: 160,
    link:"https://www.instagram.com/p/Cfwp5lqFlWV/"
}))
taquerias.push(L.marker([20.647874731668377, -103.40631870501801], {
    nombre: "Tacos Alvarez",
    direccion : "Anáhuac 3980, Cd del Sol",
    ticket: 100,
    link:"https://www.instagram.com/p/CgKcz8dlXs0/"
}))
marisquerias.push(L.marker([20.689092624300883, -103.38718727433002], {
    nombre: "Naya cocina de puerto",
    direccion : "Jose Enrique Rodó 2858 Esq, Av Terranova",
    ticket: 400,
    link:"https://www.instagram.com/p/CgYGwurFu2i/"
}))
hamburgeseriasYhotdogs.push(L.marker([20.65072025223148, -103.41855072036138], {
    nombre: "Hamburguesas y Hot dogs el May",
    direccion : "Av Moctezuma 4712, Mirador del Sol",
    ticket: 150,
    link:"https://www.instagram.com/p/Cge8c1YFHgT/"
}))
taquerias.push(L.marker([20.65567268583528, -103.31791128967433], {
    nombre: "¿Dónde Maria?",
    direccion : "Calz. Revolución 1846, Universitaria",
    ticket: 150,
    link:"https://www.instagram.com/p/Cgh28aJls11/"
}))
cortes_restauante.push(L.marker([20.66966593690453, -103.4311249338532], {
    nombre: "Fuego asador",
    direccion : "Av. Ludwig Van Beethoven 5495, La Estancia",
    ticket: 280,
    link:"https://www.instagram.com/p/CgnJleOFsWE/"
}))
marisquerias.push(L.marker([20.672792165459725, -103.430398562689], {
    nombre: "Capitán Mantarraya",
    direccion : "45030, La Estancia, 45030 Zapopan, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/Cg5ICXJlM78/"
}))

hamburgeserias.push(L.marker([20.664383591251276, -103.43674906268922], {
    nombre: "Reds Burgers",
    direccion : "Av. Piotr Ilich Tchaikovski 581, Arcos de Guadalupe",
    ticket: 200,
    link:"https://www.instagram.com/p/CgsoGPIOjL3/"
}))

cocina_Oriental.push(L.marker([20.662913522464507, -103.40416751851002], {
    nombre: "Isao Chapalita",
    direccion : "Av. Tepeyac #950, Chapalita Sur",
    ticket: 350,
    link:"https://www.instagram.com/p/ChurdhjuvuK/"
}))

cortes_restauante.push(L.marker([20.684407537979737, -103.37530456268868], {
    nombre: "Asador del rey",
    direccion : "C. Agustín de la Rosa 396, Ladrón de Guevara",
    ticket: 250,
    link:"https://www.instagram.com/p/Ch0R0qqv_QK/"
}))
cafeterias.push(L.marker([20.673682702378013, -103.3684506491966], {
    nombre: "The coffe legacy",
    direccion : "Av. Chapultepec Sur 165, Col Americana, Americana",
    ticket: 200,
    link:"https://www.instagram.com/p/Ch5EsOWLxz3/"
}))
bbqY_ahumador.push(L.marker([20.74025161858399, -103.43211177617954], {
    nombre: "13 Cerdos",
    direccion : "Av Valle de Atemajac 2445, Jardines del Valle",
    ticket: 250,
    link:"https://www.instagram.com/p/Ch-P0DdpUQd/"
}))
taquerias.push(L.marker([20.700353758575197, -103.35570217803169], {
    nombre: "Tripitas Don Ramón",
    direccion : "Chiapas 1538, San Miguel de Mezquitan",
    ticket: 120,
    link:"https://www.instagram.com/p/CiBHupLtU7v/"
}))

cortes_restauante.push(L.marker([20.652086950696056, -103.42278957618198], {
    nombre: "Sonora's Meat Patria",
    direccion : "Av. Patria 1500, Sta Catalina",
    ticket: 220,
    link:"https://www.instagram.com/p/Cif5azGJH3C/"
}))

cortes_restauante.push(L.marker([20.566360762217474, -103.4584598915279], {
    nombre: "Sonora's Meat Santa Anita",
    direccion : "Av. Adolfo López Mateos Sur 5565, Provenza Residencial",
    ticket: 220,
    link:"https://www.instagram.com/p/CiGRs62Ov5a/"
}))
taquerias.push(L.marker([20.710180885340836, -103.3483654780314], {
    nombre: "Tacos Luis",
    direccion : "C. Federico E. Ibarra 1077, Santa Monica",
    ticket: 150,
    link:"https://www.instagram.com/p/C0pOf4OukNx/"
}))
taquerias.push(L.marker([20.66899598484659, -103.43138799152497], {
    nombre: "Barbacoa Gamero",
    direccion : "Av Rafael Sanzio 405-A, La Estancia",
    ticket: 150,
    link:"https://www.instagram.com/p/CiiM2uyvksg/"
}))
tortas_Ahogadas.push(L.marker([20.66900602312238, -103.43134507618154], {
    nombre: "Tortas ahogadas Héroes",
    direccion : "C. Francia 19, Moderna",
    ticket: 100,
    link:"https://www.instagram.com/p/Cik7pZeM8Ht/"
}))
marisquerias.push(L.marker([20.67450909096925, -103.4320552780324], {
    nombre: "La Merluza",
    direccion : "Av. Sebastian Bach 5591, La Estancia",
    ticket: 270,
    link:"https://www.instagram.com/p/CisvxJoLKrB/"
}))

cocina_Oriental.push(L.marker([20.67450909096925, -103.43194798967383], {
    nombre: "Tomomi Ramen",
    direccion : "Rcda. del Fuego 2876, Rinconada del Bosque",
    ticket: 250,
    link:"https://www.instagram.com/p/CivnTZpJ2nq/"
}))
cocina_Oriental.push(L.marker([20.675275462502846, -103.36888611850966], {
    nombre: "Wasoku Izakaya",
    direccion : "Av. Chapultepec Sur 66, Col Americana, Americana",
    ticket: 320,
    link:"https://www.instagram.com/p/CiyFevXMf3g/"
}))
marisquerias.push(L.marker([20.669198201831904, -103.42642283385328], {
    nombre: "Pezcallito",
    direccion : "Av. Ludwig Van Beethoven 5104, La Estancia",
    ticket: 260,
    link:"https://www.instagram.com/p/C7cVWepOh1Z/"
}))
hamburgeseriasYhotdogs.push(L.marker([20.680335356420297, -103.39225276180288], {
    nombre: "Lonches Leo",
    direccion : "Diag. Golfo de Cortés 2966, Vallarta Nte.",
    ticket: 100,
    link:"https://www.instagram.com/p/Ci3SNtDLXIl/"
}))
taquerias.push(L.marker([20.685239187849653, -103.36935096268863], {
    nombre: "Tacos el Rojo",
    direccion : "C. Juan Álvarez 1600, Villaseñor",
    ticket: 170,
    link:"https://www.instagram.com/p/Ci5vLIiuSxF/"
}))
menuderias.push(L.marker([20.580871418775203, -103.42812059152743], {
    nombre: "La casa de las vacas",
    direccion : "Av. Camino real a colima 149",
    ticket: 230,
    link:"https://www.instagram.com/p/Ci8kKvkM-k8/"
}))
hotDogs.push(L.marker([20.684360214410468, -103.37067347618114], {
    nombre: "Dogos el chavito",
    direccion : "C. Manuel Acuña 1599, Santa Teresita",
    ticket: 80,
    link:"https://www.instagram.com/p/C4t4IehOd7y/"
}))
gusguerias.push(L.marker([20.66480492923354, -103.43707290686851], {
    nombre: "El paso alegre",
    direccion : "C. Comerciantes, Arcos de Guadalupe",
    ticket: 100,
    link:"https://www.instagram.com/p/CjB1b5bvJy5/"
}))
bbqY_ahumador.push(L.marker([20.67433372918714, -103.36839053570414], {
    nombre: "The bonehouse ",
    direccion : "Av. Chapultepec Sur 137, Col Americana, Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/CjEDFf5rIfd/"
}))
cocina_Oriental.push(L.marker([20.710896149019984, -103.41309146268792], {
    nombre: "Talento Restaurante",
    direccion : "P.º Andares 4965-Interior 425, Puerta de Hierro",
    ticket: 450,
    link:"https://www.instagram.com/p/CjG8I_zrmuI/"
}))
cocina_Oriental.push(L.marker([20.654481526499808, -103.40118143385365], {
    nombre: "Muchang",
    direccion : "Av. Adolfo López Mateos Sur 2198, Cd del Sol",
    ticket: 250,
    link:"https://www.instagram.com/p/C12hmQ9S9bI/"
}))
cafeterias.push(L.marker([20.55173776317508, -103.45850057618482], {
    nombre: "La plantería vivero café",
    direccion : "Av. Camino Real a Colima 3024, Hacienda del Oro",
    ticket: 180,
    link:"https://www.instagram.com/p/CjRTkr-Proe/"
}))
marisquerias.push(L.marker([20.675806075387563, -103.36887416083789], {
    nombre: "Pelícano oyster bar",
    direccion : "Av. Chapultepec Sur 42, Col Americana, Americana",
    ticket: 220,
    link:"https://www.instagram.com/p/CjWeDTFgDqY/"
}))
taquerias.push(L.marker([20.66197491708865, -103.42860060501759], {
    nombre: "Los de Guadalupe",
    direccion : "Av Guadalupe 5347-LOCAL 5, Arcos de Guadalupe",
    ticket: 160,
    link:"https://www.instagram.com/p/CjbmLWpJDUc/"
}))
cortes_restauante.push(L.marker([20.65445391255923, -103.40121240316684], {
    nombre: "Hacienda Canelos",
    direccion : "Av. Adolfo López Mateos Sur 2198, Cd del Sol",
    ticket: 350,
    link:"https://www.instagram.com/p/Cx3fvVuPDh6/"
}))
taquerias.push(L.marker([20.68234157853341, -103.38544610501701], {
    nombre: "Taquarte",
    direccion : "Av. Rubén Darío 425, Circunvalación Vallarta",
    ticket: 300,
    link:"https://www.instagram.com/p/CjiW58Yt0z2/"
}))
taquerias.push(L.marker([20.66720041849522, -103.42459693385335], {
    nombre: "Tacos el Güero",
    direccion : "Av. Patria & Calle Santo Tomás de Aquino 800, Av. Patria, La Estancia",
    ticket: 130,
    link:"https://www.instagram.com/p/CjjW53CvyKS/"
}))
brunchs.push(L.marker([20.6767661776717, -103.35370317405734], {
    nombre: "El jardín de la Pichina",
    direccion : "C. José María Morelos 710, Centro",
    ticket: 200,
    link:"https://www.instagram.com/p/Cjk2J-uvJMl/"
}))
alitas.push(L.marker([20.673811295888164, -103.4170722780324], {
    nombre: "Caribbean wings",
    direccion : "Av Manuel J. Clouthier 170, Prados Vallarta",
    ticket: 230,
    link:"https://www.instagram.com/p/C8-zomouyfe/"
}))
marisquerias.push(L.marker([20.693435914213236, -103.37253063385258], {
    nombre: "Colomitos",
    direccion : "C. Colomos 2218, Ayuntamiento",
    ticket: 280,
    link:"https://www.instagram.com/p/C8xZEyvujtd/"
}))
antojitos_Mexicanos.push(L.marker([20.653801100464516, -103.34798936392167], {
    nombre: "Hermanas coraje",
    direccion : "Chicago 1280, La Aurora",
    ticket: 220,
    link:"https://www.instagram.com/p/CjyPBR0sp9j/"
}))
taquerias.push(L.marker([20.68441919075017, -103.36963772036042], {
    nombre: "Tacos Chava",
    direccion : "C. Manuel Acuña 1554, Santa Teresita",
    ticket: 110,
    link:"https://www.instagram.com/p/C5M0nMROCEJ/"
}))
taquerias.push(L.marker([20.68153623030435, -103.36825680686805], {
    nombre: "Tacos el Pirata",
    direccion : "Calle Garibaldi 1400, Santa Teresita",
    ticket: 90,
    link:"https://www.instagram.com/p/Cj6fp60O3hC/"
}))
taquerias.push(L.marker([20.6436537402875, -103.37995778967465], {
    nombre: "Tacos los de papa",
    direccion : "Isla Cozumel 2794, Jardines de La Cruz",
    ticket: 120,
    link:"https://www.instagram.com/p/Cj9GNvnMKXX/"
}))
taquerias.push(L.marker([20.67008620743463, -103.31635809274083], {
    nombre: "Tacos la 54 can can",
    direccion : "Basilio Vadillo 82, La Penal",
    ticket: 100,
    link:"https://www.instagram.com/p/Cj9GNvnMKXX/"
}))
hamburgeseriasYhotdogs.push(L.marker([20.67384231986981, -103.36591339152478], {
    nombre: "Hamburguesería central",
    direccion : "Libertad 1878, Col Americana, Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/C6UVktEujVv/"
}))
panaderias.push(L.marker([20.669557231060523, -103.43602978967391], {
    nombre: "Solangel cakes",
    direccion : "Av. Piotr Ilich Tchaikovski 416-A, La Estancia",
    ticket: 120,
    link:"https://www.instagram.com/p/C5EFaL4O-Qf/"
}))
marisquerias.push(L.marker([20.715811125342668, -103.3810268203595], {
    nombre: "Cajun house fingers",
    direccion : "Av. Manuel Ávila Camacho 3255a, Jacarandas",
    ticket: 230,
    link:"https://www.instagram.com/p/CkG-z66MbZc/"
}))
gusguerias.push(L.marker([20.725772305895642, -103.47141808967235], {
    nombre: "Top fries",
    direccion : "Av. Paseo del Anochecer 311-Planta Alta, Local 8, Solares Residencial",
    ticket: 100,
    link:"https://www.instagram.com/p/CrOyyZrpbDs/"
}))
antojitos_Mexicanos.push(L.marker([20.68486134901936, -103.36938846268868], {
    nombre: "La birotería",
    direccion : "C. Pedro Antonio Buzeta 476 B, Santa Teresita",
    ticket: 80,
    link:"https://www.instagram.com/p/CkL6xE3tjaw/"
}))
taquerias.push(L.marker([20.66531540465753, -103.43191076268926], {
    nombre: "Los Lojan",
    direccion : "Av Rafael Sanzio 598B, Arcos de Guadalupe",
    ticket: 120,
    link:"https://www.instagram.com/p/CkRp8Y9M_SC/"
}))
pizzerias.push(L.marker([20.664961444681087, -103.36442829152504], {
    nombre: "El merodeador",
    direccion : "C. Venezuela 758, Moderna",
    ticket: 160,
    link:"https://www.instagram.com/p/CkUXzBhLsWa/"
}))
tortas_Ahogadas.push(L.marker([20.689510597825286, -103.36953320501678], {
    nombre: "Ahogadas Beto",
    direccion : "C. Pedro Antonio Buzeta 757, Ladrón de Guevara",
    ticket: 100,
    link:"https://www.instagram.com/p/CkZtauVAINY/"
}))
antojitos_Mexicanos.push(L.marker([20.666837857259278, -103.40444889152505], {
    nombre: "Chilaquiles Poncho",
    direccion : "San Agustín 1299B, Jardín de San Ignacio",
    ticket: 120,
    link:"https://www.instagram.com/p/Cd_Ytfxu00C/"
}))
gusguerias.push(L.marker([20.63661486807249, -103.42708944734659], {
    nombre: "Elotes el chino",
    direccion : "Martha Dueñas 5442, Paseos del Sol",
    ticket: 60,
    link:"https://www.instagram.com/p/C3G4BFguryo/"
}))
marisquerias.push(L.marker([20.65432972669048, -103.44310619337638], {
    nombre: "La veintiuna cocina de mar",
    direccion : "Calle santo tomas 1136, Vista del Pinar, Exitmex",
    ticket: 280,
    link:"https://www.instagram.com/p/Cko5XVFsPKw/"
}))
taquerias.push(L.marker([20.667310840797835, -103.42459693385335], {
    nombre: "Tacos el Güero taquiquesos",
    direccion : "Av. Patria & Calle Santo Tomás de Aquino 800, Av. Patria, La Estancia",
    ticket: 110,
    link:"https://www.instagram.com/p/CkrePqdtHBh/"
}))
bares.push(L.marker([20.67378951239266, -103.37697119152482], {
    nombre: "Fatcharlie",
    direccion : "C. Manuel López Cotilla 1940, Col Americana, Lafayette",
    ticket: 300,
    link:"https://www.instagram.com/p/Ckw0fMxJhy9/"
}))
cortes_restauante.push(L.marker([20.646858915573407, -103.38871397618215], {
    nombre: "Cocina tres 16",
    direccion : "C. Diamante 2475, Bosques de La Victoria",
    ticket: 250,
    link:"https://www.instagram.com/p/CkzNvuND1zd/"
}))
gusguerias.push(L.marker([20.67327228887862, -103.38150787618143], {
    nombre: "Lucha papa",
    direccion : "C. Manuel López Cotilla 2105, Arcos Vallarta",
    ticket: 70,
    link:"https://www.instagram.com/p/CxO0UFrOryv/"
}))
cortes_restauante.push(L.marker([20.657581366440432, -103.33061324734602], {
    nombre: "El Gorderón",
    direccion : "C. Rio Juchipila 1058, Olímpica",
    ticket: 220,
    link:"https://www.instagram.com/p/Ck12SdPsbpp/"
}))
hotDogs.push(L.marker([20.674571350948987, -103.38137529864463], {
    nombre: "Dogos lupe",
    direccion : "Francisco de Quevedo, Arcos Vallarta",
    ticket: 120,
    link:"https://www.instagram.com/p/Ck3seE6PxRj/"
}))
nieves.push(L.marker([20.694604312666026, -103.37577562036012], {
    nombre: "Amorino",
    direccion : "Av Adolfo López Mateos Nte 2405 Local L46 Primer Piso, Italia Providencia",
    ticket: 150,
    link:"https://www.instagram.com/p/Ck66Ba5pcq1/"
}))
loncheria.push(L.marker([20.678428145757675, -103.39191924919642], {
    nombre: "Crujys",
    direccion : "Diag. Golfo de Cortés 3044, Vallarta Nte.",
    ticket: 150,
    link:"https://www.instagram.com/p/Ck9iBgWrvCY/"
}))
sushis.push(L.marker([20.657983661118536, -103.33015801480819], {
    nombre: "Sushikyoday",
    direccion : "C. Rio Juchipila 1034, Olímpica",
    ticket: 200,
    link:"https://www.instagram.com/p/ClAGtnwshwN/"
}))
taquerias.push(L.marker([20.675977423840635, -103.37029686268896], {
    nombre: "Jose Cande Chicharronería",
    direccion : "C. José María Morelos 1739, Col Americana, Americana",
    ticket: 200,
    link:"https://www.instagram.com/p/ClGxghOO7u7/"
}))
taquerias.push(L.marker([20.632507520390835, -103.31628132274825], {
    nombre: "Tacos de Barbacoa Mickey",
    direccion : "Av. Marcos Montero Ruiz 891, Alamo Lomas",
    ticket: 100,
    link:"https://www.instagram.com/p/ClO0qMuL3qa/"
}))
bares.push(L.marker([20.67045871547765, -103.36924750501734], {
    nombre: "Porter",
    direccion : "C. José Guadalupe Zuno Hernández 2027, Col Americana, Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/ClUpXKqLdva/"
}))
taquerias.push(L.marker([20.633102972014957, -103.43242580501843], {
    nombre: "Felipe Zetter",
    direccion : "C. Felipe Zetter 3495, Paseos del Sol",
    ticket: 150,
    link:"https://www.instagram.com/p/C2Lj0yDurQC/"
}))
antojitos_Mexicanos.push(L.marker([20.676803409600765, -103.3725764626889], {
    nombre: "Tostadas Jimmy'z",
    direccion : "Av. Miguel Hidalgo y Costilla 1679 B, Ladrón de Guevara, Lafayette",
    ticket: 200,
    link:"https://www.instagram.com/p/ClZ31JArXjW/"
}))
taquerias.push(L.marker([20.64807643454158, -103.40290343200289], {
    nombre: "Tacos Al Vapor Oscar",
    direccion : "C. Aguamarina 1985, La Girald",
    ticket: 80,
    link:"https://www.instagram.com/p/Cleo7NTspGU/"
}))
panaderias.push(L.marker([20.635851552280325, -103.4267093031673], {
    nombre: "Brier",
    direccion : "Av. Enrique Ladrón de Guevara 3319D, entre Sara Bertha de la Torre y Marina Cruz",
    ticket: 80,
    link:"https://www.instagram.com/p/ClkPsMeN4ph/"
}))
marisquerias.push(L.marker([20.672891527311226, -103.42481973200213], {
    nombre: "Rinconcito  Ensenada",
    direccion : "Av. Sebastian Bach 5097, La Estancia",
    ticket: 220,
    link:"https://www.instagram.com/p/Cu-QN2zJGeu/"
}))
marisquerias.push(L.marker([20.698110135296908, -103.37243301850904], {
    nombre: "Boca del Cielo Select",
    direccion : "Av. de las Américas 1254, Country Club",
    ticket: 300,
    link:"https://www.instagram.com/p/ClunrmpPDon/"
}))
marisquerias.push(L.marker([20.671133072630855, -103.3781030203607], {
    nombre: "Octo",
    direccion : "C. Miguel Lerdo de Tejada 2420, Lafayette",
    ticket: 1500,
    link:"https://www.instagram.com/p/ClxHOIbJtE7/"
}))
taquerias.push(L.marker([20.67712206030374, -103.4367352682419], {
    nombre: "La Ley del Monte",
    direccion : "Independencia 200, Sta María del Pueblito",
    ticket: 180,
    link:"https://www.instagram.com/p/Cl4qPrBLVJq/"
}))
hamburgeserias.push(L.marker([20.707612357753632, -103.41315127625883], {
    nombre: "Shake Shack",
    direccion : "P.º de los Virreyes 45, Puerta de Hierro",
    ticket: 230,
    link:"https://www.instagram.com/p/CmHcyEgMVJN/"
}))
panaderias.push(L.marker([20.672132376629552, -103.34355142266527], {
    nombre: "Panadería Esperanza",
    direccion : "Av. 16 de Septiembre 555, Mexicaltzingo",
    ticket: 50,
    link:"https://www.instagram.com/p/CmK3w24rRKF/"
}))
taquerias.push(L.marker([20.677574637007584, -103.43648646268883], {
    nombre: "Birreria Los Rojos de TJ ",
    direccion : "Independencia 256, Sta María del Pueblito",
    ticket: 200,
    link:"https://www.instagram.com/p/C1Psr0rulOm/"
}))
taquerias.push(L.marker([20.668399923416008, -103.36204830774413], {
    nombre: "Tacos Don José",
    direccion : "Calle Argentina 595, Americana",
    ticket: 100,
    link:"https://www.instagram.com/p/CmfB6LCKxen/"
}))
brunchs.push(L.marker([20.673021317280213, -103.37146301850973], {
    nombre: "Tercio ",
    direccion : "C. Gral. San Martín 177, Col Americana, Lafayette",
    ticket: 220,
    link:"https://www.instagram.com/p/Cmh3hR6KZNd/"
}))
taquerias.push(L.marker([20.677593136973346, -103.43643281850956], {
    nombre: "Taquería Los Xolos ",
    direccion : "Independencia 256, Sta María del Pueblito",
    ticket: 150,
    link:"https://www.instagram.com/p/CmpiF4tqUZu/"
}))
taquerias.push(L.marker([20.651618455691377, -103.40625387333782], {
    nombre: "Tacos Álvarez ",
    direccion : "Anáhuac 3980, Cd del Sol",
    ticket: 120,
    link:"https://www.instagram.com/p/CnS4I8UKDM2/"
}))
hamburgeserias.push(L.marker([20.662656087203146, -103.40052722036104], {
    nombre: "El Canibal",
    direccion : "Av de Las Rosas 432, Chapalita Sur",
    ticket: 250,
    link:"https://www.instagram.com/p/CnS4I8UKDM2/"
}))
cortes_restauante.push(L.marker([20.668774700070912, -103.39155666268911], {
    nombre: "Casa Macaria",
    direccion : "Av. Adolfo López Mateos Sur 648, Jardines de Los Arcos",
    ticket: 350,
    link:"https://www.instagram.com/p/CwDuc3jKqLS/"
}))
antojitos_Mexicanos.push(L.marker([20.68029291933211, -103.43567869152459], {
    nombre: "Los muchachos de Zapopan",
    direccion : "Independencia 85c, Sta María del Pueblito",
    ticket: 150,
    link:"https://www.instagram.com/p/CnaAYxXKAnp/"
}))
cortes_restauante.push(L.marker([20.665632628029662, -103.36825294919679], {
    nombre: "Río Viejo",
    direccion : "Av. Chapultepec Sur 693, Moderna",
    ticket: 400,
    link:"https://www.instagram.com/p/CndA_X8Khe8/"
}))
taquerias.push(L.marker([20.65091083098311, -103.38970106268967], {
    nombre: "Tacos el Chino Jr.",
    direccion : "Av. Rcda. de las Praderas 2412, Verde Valle",
    ticket: 80,
    link:"https://www.instagram.com/p/Cnfq0UoqbDR/"
}))
taquerias.push(L.marker([20.65437254812804, -103.42926296268945], {
    nombre: "Tacos Joaquín",
    direccion : "Av. Tepeyac &, Playa Yelapa Nte. 985 Nte, Moctezuma",
    ticket: 100,
    link:"https://www.instagram.com/p/Cnh2qh-KJ3o/"
}))
panaderias.push(L.marker([20.644581778348826, -103.40146376268986], {
    nombre: "Panadería Cuca y Lupe",
    direccion : "Av. Lapizlázuli 2984, Victoria",
    ticket: 50,
    link:"https://www.instagram.com/p/Cnj1BFFK1MW/"
}))
taquerias.push(L.marker([20.666426811495885, -103.36534541850995], {
    nombre: "Tacos Gascón",
    direccion : "Av. Alemania 1803, Moderna",
    ticket: 200,
    link:"https://www.instagram.com/p/Cnpl-hbKYeV/"
}))
nieves.push(L.marker([20.67913149649966, -103.37004236268886], {
    nombre: "Nieves Momotabi",
    direccion : "Av. México 2069, Ladrón de Guevara",
    ticket: 80,
    link:"https://www.instagram.com/p/Cn0Qmt3P1VP/"
}))
gusguerias.push(L.marker([20.622379373621524, -103.39295272036216], {
    nombre: "Raspados la Sonny",
    direccion : "C. Isla Zanzibar 4339, El Sauz, 45987, El Sauz, 44987 San Pedro Tlaquepaque, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/CvvjxvLOgHZ/"
}))
cafeterias.push(L.marker([20.67314578901247, -103.36134036453998], {         
    nombre: "Modo café",
    direccion : "Calle Argentina 268, Col Americana",
    ticket: 180,
    link:"https://www.tiktok.com/@fatphilia.gdl/video/7194141815937699077"
}))
antojitos_Mexicanos.push(L.marker([20.666078650415177, -103.40401433570428], {         
    nombre: "La Morenita del Santuario",
    direccion : "Av Guadalupe 1263, Chapalita",
    ticket: 140,
    link:"https://www.instagram.com/p/CoNiFS6MKyC/"
}))
taquerias.push(L.marker([20.67774774811447, -103.421483060837868], {         
    nombre: "Tacos los Caramelos",
    direccion : "A Las Praderas 83, Vallarta la Patria",
    ticket: 90,
    link:"https://www.instagram.com/p/CoQNCvCMnKe/"
}))
antojitos_Mexicanos.push(L.marker([20.644328419083806, -103.36271476083881], {         
    nombre: "Los originales de Santa Tere",
    direccion : "A una cuadra de Lázaro Cárdenas, Av 8 de Julio 2292-2, Colón Industrial",
    ticket: 90,
    link:"https://www.instagram.com/p/Cxa1cjbO1ym/"
}))
sushis.push(L.marker([20.601490010600184, -103.41226766131938], {         
    nombre: "Soru Sushi",
    direccion : "Av. Primavera 2944, Parques del Bosque",
    ticket: 200,
    link:"https://www.instagram.com/p/CoX36rVpLG7"
}))
taquerias.push(L.marker([20.665911574834496, -103.36147096649124], {         
    nombre: "Barbacoa El Torito",
    direccion : "Calle Argentina 761, Moderna",
    ticket: 100,
    link: "https://www.instagram.com/p/CoatLt5LUbT/"
}))
taquerias.push(L.marker([20.622915189043685, -103.36168823479021], {         
    nombre: "Tacos El Meño",
    direccion : "Av. Patria 1050, Echeverría",
    ticket: 150,
    link:"https://www.instagram.com/p/Coc8_W5sYy_/"
}))
alitas.push(L.marker([20.660165505278083, -103.44053368976797], {         
    nombre: "Caribban Wings",
    direccion : "Av Guadalupe 6304, Jardines de Chapalita",
    ticket: 200,
    link:"https://www.instagram.com/p/CjqN3AGuYFE/"
}))
cortes_restauante.push(L.marker([20.628965759655504, -103.41997536599156], {         
    nombre: "Colomos restaurante",
    direccion : "Av. Nicolás Copérnico 3675, Arboledas",
    ticket: 250,
    link:"https://www.instagram.com/p/CyMvQgBuSJI/"
}))
munchies.push(L.marker([20.639522564945334, -103.31415963529756], {         
    nombre: "Nieves de Garrafa Chapalita GOURMET",
    direccion : "C. Juárez 243, Centro, 45500",
    ticket: 50,
    link:"https://www.instagram.com/p/CosjNnxNkXK/"
}))
taquerias.push(L.marker([20.670012065384046, -103.38208106751729], {         
    nombre: "La Ambivalencia",
    direccion : "C. Argensola 293, Arcos Sur",
    ticket: 180,
    link:"https://www.instagram.com/p/Co0QwEOrA1s/"
}))
taquerias.push(L.marker([20.69318186497339, -103.38499943476306], {         
    nombre: "Nápoles",
    direccion : "Av. Rubén Darío 1045, Lomas de Providencia",
    ticket: 450,
    link:"https://www.instagram.com/p/Co522u8rpXK/"
}))
taquerias.push(L.marker([20.68326472917018, -103.38570365011202], {         
    nombre: "Taquearte ",
    direccion : "Av. Rubén Darío 425, Circunvalación Vallarta",
    ticket: 250,
    link:"https://www.instagram.com/p/Co77vD7OYZB/"
}))
taquerias.push(L.marker([20.647170615018318, -103.43236296598464], {         
    nombre: "Asadas El Concho ",
    direccion : "C. Volcan de Baru 1887b, Colli Urbano",
    ticket: 150,
    link:"https://www.instagram.com/p/Co_AprUpfbZ/"
}))
marisquerias.push(L.marker([20.710379208515494, -103.41261254268586], {         
    nombre: "La docena",
    direccion : "Blvrd Puerta de Hierro 4659, Puerta de Hierro",
    ticket: 600,
    link:"https://www.instagram.com/p/CpIypWnuT6O/"
}))
antojitos_Mexicanos.push(L.marker([20.62951201264921, -103.37242413530122], {         
    nombre: "Tostadas y Tortas Pancho",
    direccion : "C. Longinos Cadena 1926, Lomas de Polanco",
    ticket: 150,
    link:"https://www.instagram.com/p/CpLaFFSNDbL/"
}))
munchies.push(L.marker([20.726524605643633, -103.47120356595414], {         
    nombre: "Top Fries",
    direccion : "Av. Paseo del Anochecer 311-Planta Alta, Local 8, Solares Residencial",
    ticket: 100,
    link:"https://www.instagram.com/p/CpOCgy6r-Eu/"
}))
marisquerias.push(L.marker([20.70319622137241, -103.37782634320234], {         
    nombre: "Happy Shrimp",
    direccion : "C. Ottawa 1568, Providencia",
    ticket: 250,
    link:"https://www.instagram.com/p/CpQqaqfPbKP/"
}))
marisquerias.push(L.marker([20.70319622137241, -103.37782634320234], {         
    nombre: "Happy Shrimp",
    direccion : "C. Ottawa 1568, Providencia",
    ticket: 250,
    link:"https://www.instagram.com/p/CpQqaqfPbKP/"
}))
bares.push(L.marker([20.677301865589392, -103.35333913528292], {         
    nombre: "Cantina La Pichina",
    direccion : "C. José María Morelos 710, Centro",
    ticket: 200,
    link:"https://www.instagram.com/p/CpTkHstsCzh/"
}))
taquerias.push(L.marker([20.678337214248284, -103.434490952169], {         
    nombre: "Birria Los Rojos de TJ",
    direccion : "Independencia 256, Sta María del Pueblito",
    ticket: 200,
    link:"https://www.instagram.com/p/CpVNdrHM5d9/"
}))
taquerias.push(L.marker([20.62658759610198, -103.2632470511612], {         
    nombre: "Tacos Gus",
    direccion : "45418, Loma Grande 448, Loma Dorada Delegación a",
    ticket: 150,
    link:"https://www.instagram.com/p/CpYPwXmp_X7/"
}))
antojitos_Mexicanos.push(L.marker([20.68041599795184, -103.3695778040777], {         
    nombre: "Tamales Bertha",
    direccion : "C. Pedro Antonio Buzeta 205, Santa Teresita",
    ticket: 100,
    link:"https://www.instagram.com/p/CpgdkeWNFPm/"
}))
marisquerias.push(L.marker([20.740543116261456, -103.38802232732911], {         
    nombre: "Sabrositos del Mar",
    direccion : "Anillo Perif. Nte. 203, El Vigía",
    ticket: 250,
    link:"https://www.instagram.com/p/Cpikkd_rROX/"
}))
taquerias.push(L.marker([20.671341709675612, -103.36828835804597], {         
    nombre: "Tomate Taquería",
    direccion : "Av. Chapultepec Sur 361, Col Americana, Americana",
    ticket: 200,
    link:"https://www.instagram.com/p/CplKSnQOeR9/"
}))
nieves.push(L.marker([20.70440613887924, -103.34112972734293], {         
    nombre: "La Italiana",
    direccion : "C. José Ignacio Solórzano 608-620, Jardines Alcalde",
    ticket: 50,
    link:"https://www.instagram.com/p/CpqWPQNOge6/"
}))
marisquerias.push(L.marker([20.667212467941024, -103.42397274321621], {         
    nombre: "Save",
    direccion : "Av. Patria 574, Jardines de Guadalupe",
    ticket: 400,
    link:"https://www.instagram.com/p/CptVY_lPhfv/"
}))
cocina_Oriental.push(L.marker([20.691465239349377, -103.38675001993235], {         
    nombre: "Tsuri",
    direccion : "Av Terranova 715, Prados Providencia",
    ticket: 450,
    link:"https://www.instagram.com/p/CvVOB9ZuqVy/"
}))
nieves.push(L.marker([20.696285329090053, -103.3196857045855], {         
    nombre: "Heladeli",
    direccion : "C. Puerto Soto La Marina 1648, Circunvalación Belisario",
    ticket: 50,
    link:"https://www.instagram.com/p/CpyJEmsv7Gt/"
}))
bares.push(L.marker([20.709526860592533, -103.41002724268597], {         
    nombre: "Testarossa",
    direccion : "Av Real Acueducto, Av. Patria 110, Puerta de Hierro",
    ticket: 800,
    link:"https://www.instagram.com/p/Cp1ExXqOxKA/"
}))
hotDogs.push(L.marker([20.68535138018519, -103.37076839666001], {         
    nombre: "Dogos El Chavito",
    direccion : "Manuel M. Dieguez 543, Santa Teresita",
    ticket: 100,
    link:"https://www.instagram.com/p/Cp3rWuyDlh5/"
}))
brunchs.push(L.marker([20.670529185936783, -103.37103356597561], {         
    nombre: "Alba Cocina Diversa",
    direccion : "Calle Efraín González Luna 2142, Col Americana, Obrera",
    ticket: 200,
    link:"https://www.instagram.com/p/Cp5Ocj8MdO_/"
}))
menuderias.push(L.marker([20.66311466414621, -103.42217975804918], {         
    nombre: "Menudería y Cenaduría La Estancia",
    direccion : "Av Guadalupe 5085, Jardines del Tepeyac",
    ticket: 250,
    link:"https://www.instagram.com/p/Cp8W0xov6Qc/"
}))
brunchs.push(L.marker([20.671175863573215, -103.37262264270099], {         
    nombre: "Espacio Abierto",
    direccion : "C. Simón Bolívar 320, Col Americana, Obrera",
    ticket: 200,
    link:"https://www.instagram.com/p/Cp-gbCdr1Gy/"
}))
munchies.push(L.marker([20.662295414433085, -103.40437716597891], {         
    nombre: "Donas Ajijic",
    direccion : "Av. del Árbol 399, Chapalita Sur",
    ticket: 100,
    link:"https://www.instagram.com/p/CqBjeBiOPYW/"
}))
antojitos_Mexicanos.push(L.marker([20.685460471200876, -103.37692354320917], {         
    nombre: "La Santa Cruz",
    direccion : "Av. Manuel Acuña 2406, Ladrón de Guevara",
    ticket: 150,
    link:"https://www.instagram.com/p/CqGz7tHPgzl/"
}))
munchies.push(L.marker([20.663937248399243, -103.40111117339374], {         
    nombre: "Mochibrothers",
    direccion : "Av. Tepeyac 655, Chapalita",
    ticket: 150,
    link:"https://www.instagram.com/p/CqJGaR5uuQS/"
}))
marisquerias.push(L.marker([20.669887300689396, -103.42998078873667], {         
    nombre: "Mr. & Mrs. Shrimpy",
    direccion : "Av. Ludwig Van Beethoven 5311, La Estancia",
    ticket: 250,
    link:"https://www.instagram.com/p/CqL214GrUPT/"
}))
bares.push(L.marker([20.639636542807963, -103.3197392659874], {         
    nombre: "Ronyto",
    direccion : "Río Independencia 507",
    ticket: 200,
    link:"https://www.instagram.com/p/CqOW0EWu1jr/"
}))
munchies.push(L.marker([20.67107405501269, -103.34145530804535], {         
    nombre: "Pastelería Esperanza",
    direccion : "Av. 16 de Septiembre 555, Mexicaltzingo",
    ticket: 100,
    link:"https://www.instagram.com/p/CqTjV6dptz5/"
}))
marisquerias.push(L.marker([20.64285365536058, -103.43287281943712], {         
    nombre: "La Palapa del Tigre",
    direccion : "Av. Nicolás Copérnico 2228, Colli Urbano",
    ticket: 250,
    link:"https://www.instagram.com/p/CqWGBAcujTe/"
}))
taquerias.push(L.marker([20.74212351033952, -103.43007990456783], {         
    nombre: "Tacos La Carreta Del Güero ",
    direccion : "Av. del Servidor Público 1532, Jardines del Valle",
    ticket: 150,
    link:"https://www.instagram.com/p/CqYtcQSOkwB/"
}))
taquerias.push(L.marker([20.68788720333996, -103.36748788924355], {         
    nombre: "Tictaco",
    direccion : "C. Andrés Terán 700, Villaseñor",
    ticket: 150,
    link:"https://www.instagram.com/p/CqgXhQYtib5/"
}))
marisquerias.push(L.marker([20.876230748140152, -103.83796729607252], {         
    nombre: "La Cuichi Crab",
    direccion : "Fraccionamiento Los Portales, C. de los Agaves 92, La Mezcaler",
    ticket: 250,
    link:"https://www.instagram.com/p/CqjEqT9v86I/"
}))
loncheria.push(L.marker([20.658423082891158, -103.36902292148646], {         
    nombre: "Lonches Rafa",
    direccion : "Naranjo 1408, Del Fresno",
    ticket: 100,
    link:"https://www.instagram.com/p/CqoIMS0gWNB/"
}))
bares.push(L.marker([20.88360290448153, -103.83959974261869], {         
    nombre: "La Capilla de Don Javier",
    direccion : "Hidalgo 34, Centro",
    ticket: 250,
    link:"https://www.instagram.com/p/Cqqt9Z1M-Ty/"
}))
antojitos_Mexicanos.push(L.marker([20.693703040434997, -103.36523698079802], {         
    nombre: "Cenaduría Doña Guille",
    direccion : "C. Jaime Nunó 1032, Mezquitan Country",
    ticket: 200,
    link:"https://www.instagram.com/p/CviSgC8vSC6/"
}))
marisquerias.push(L.marker([20.68624360859758, -103.37492755011085], {         
    nombre: "Mariscos La Comer",
    direccion : "Amado Nervo, C. Juan Álvarez, Ladron De Guevara",
    ticket: 250,
    link:"https://www.instagram.com/p/CqvvY6FLdSd/"
}))
marisquerias.push(L.marker([20.738988521746368, -103.44909620405514], {         
    nombre: "La Brujería",
    direccion : "Av. Aviación 4368-14, Jardín Real",
    ticket: 250,
    link:"https://www.instagram.com/p/CqybLOqOpbs/"
}))
cafeterias.push(L.marker([20.671353245996176, -103.35674345063026], {         
    nombre: "Miauggen Heim",
    direccion : "Av. de la Paz 1368, Col Americana, Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/Cq088yIu1fB/"
}))
antojitos_Mexicanos.push(L.marker([20.65734582021429, -103.3816772501219], {         
    nombre: "Carnes Asadas Ramón",
    direccion : "C. 4 430-442, Comercial Abastos",
    ticket: 150,
    link:"https://www.instagram.com/p/Cq3ifKrPJXC/"
}))
antojitos_Mexicanos.push(L.marker([20.65730406403331, -103.38115826598077], {         
    nombre: "Menudería Chela",
    direccion : "C. 2 4-202, Comercial Abastos",
    ticket: 150,
    link:"https://www.instagram.com/p/Cq6KXjRMNlp/"
}))
cortes_restauante.push(L.marker([20.669979003556907, -103.39157817339178], {         
    nombre: "Casa Macaria ",
    direccion : "Av. Pablo Neruda 3020, Providencia 4ta. secc, Guadalajara, Jal. ",
    ticket: 300,
    link:"https://www.instagram.com/p/CxWHUnaunm3/"
}))
loncheria.push(L.marker([20.673974361800116, -103.35102685898353], {         
    nombre: "Lonches Veliz",
    direccion : "Av. Cristóbal Colón 668A, Mexicaltzingo",
    ticket: 100,
    link:"https://www.instagram.com/p/Cq_U0ACvORh/"
}))
taquerias.push(L.marker([20.65720058256584, -103.3816457273611], {         
    nombre: "Birrería Aceves",
    direccion : "44530, C. 4 453, Comercial Abastos",
    ticket: 150,
    link:"https://www.instagram.com/p/CrB6RYQrqfD/"
}))
munchies.push(L.marker([20.672289608418694, -103.43105418080644], {         
    nombre: "Kurto's",
    direccion : "Av Rafael Sanzio 293, La Estancia",
    ticket: 150,
    link:"https://www.instagram.com/p/CrHZL7iutMq/"
}))
loncheria.push(L.marker([20.676075429828444, -103.33983735011475], {         
    nombre: "La Torta Loca",
    direccion : "Mercado Libertad Local 1173, San Juan de Dios",
    ticket: 150,
    link:"https://www.instagram.com/p/CrJnbFwttZI/"
}))
antojitos_Mexicanos.push(L.marker([20.676669625735517, -103.33987705804397], {         
    nombre: "El Chivo de Oro",
    direccion : "Mercado Libertad local 789, San Juan de Díos",
    ticket: 150,
    link:"https://www.instagram.com/p/CrMN2y5OVTY/"
}))
cocina_Oriental.push(L.marker([20.677144812991777, -103.33475914218477], {         
    nombre: "Junichi Ramen",
    direccion : "San Juan de Dios, 44360 ",
    ticket: 200,
    link:"https://www.instagram.com/p/CrRZASMxN2T/"
}))
bbqY_ahumador.push(L.marker([20.70114742982432, -103.34098394423056], {         
    nombre: "New Amsterdam",
    direccion : "Calle Paseo de los Filósofos & Calle Carlos Mérida, Jardines Alcalde",
    ticket: 250,
    link:"https://www.instagram.com/p/Cyg3NNTOSkq/"
}))
cocina_Oriental.push(L.marker([20.65491717711232, -103.3882764194325], {         
    nombre: "Tomomi Ramen",
    direccion : "Rcda. del Fuego 2876, Rinconada del Bosque",
    ticket: 250,
    link:"https://www.instagram.com/p/CrZDWZCO6WG/"
}))
marisquerias.push(L.marker([20.687145762262364, -103.36207092096154], {         
    nombre: "Ponte Trucha Negro",
    direccion : "C. Ignacio Ramírez 646, Santa Teresita",
    ticket: 250,
    link:"https://www.instagram.com/p/CrbncNxJQSt/"
}))
cafeterias.push(L.marker([20.674174275246603, -103.36809665062914], {         
    nombre: "The Coffe Legacy",
    direccion : "Av. Chapultepec Sur 165, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/CriyD_gOEvp/"
}))
cocina_Oriental.push(L.marker([20.67670054537604, -103.36878961200891], {         
    nombre: "Wasoku Izakaya",
    direccion : "Av. Chapultepec Sur 66, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/Crl5a3Hvi7Z/"
}))
marisquerias.push(L.marker([20.64608004246641, -103.37595252685163], {         
    nombre: "Mirmar",
    direccion : "C. Isla Martinica 2530, Jardines de La Cruz",
    ticket: 200,
    link:"https://www.instagram.com/p/Crod8D3r6M4/"
}))
bares.push(L.marker([20.30202403942357, -103.26127984335564], {         
    nombre: "Central Burro",
    direccion : "Carretera Ote. 5A, Centro",
    ticket: 250,
    link:"https://www.instagram.com/p/CrrFLzZM7ln/"
}))
antojitos_Mexicanos.push(L.marker([20.682011339622314, -103.3566298120065], {         
    nombre: "Yunaites",
    direccion : "Calle Garibaldi 824, Jesús",
    ticket: 200,
    link:"https://www.instagram.com/p/CrtQQQjPib4/"
}))
menuderias.push(L.marker([20.74559805276893, -103.3741159193976], {         
    nombre: "Muchachos de Zapopan",
    direccion : "Esquina, Av de las torres, Jardines de las Begonias 865",
    ticket: 200,
    link:"https://www.instagram.com/p/CrySnl4sUcC/"
}))
bbqY_ahumador.push(L.marker([20.674805224157254, -103.36761811252309], {         
    nombre: "The Bone House",
    direccion : "Av. Chapultepec Sur 137, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/Cr1b4okr4Xp/"
}))
marisquerias.push(L.marker([20.67679953019605, -103.36836995855775], {         
    nombre: "Pelicano Oyster",
    direccion : "Av. Chapultepec Sur 42, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/Cr6mr6Mvxt3/"
}))
tortas_Ahogadas.push(L.marker([20.68997200855782, -103.36944742734848], {         
    nombre: "Ahogadas Beto",
    direccion : "C. Pedro Antonio Buzeta 757, Ladrón de Guevara",
    ticket: 150,
    link:"https://www.instagram.com/p/Cr8uj9Du-eW/"
}))
cocina_Oriental.push(L.marker([20.669509024484764, -103.36900813477196], {         
    nombre: "King Roll",
    direccion : "Av chapultepec 460 local A esquina",
    ticket: 250,
    link:"https://www.instagram.com/p/Cr_vn11OqVS/"
}))
bbqY_ahumador.push(L.marker([20.64023634550188, -103.42040717340291], {         
    nombre: "Pollos Bravo",
    direccion : "Av. Patria 2974, Colli Urbano",
    ticket: 200,
    link:"https://www.instagram.com/p/CsHSKbGLS8M/"
}))
bbqY_ahumador.push(L.marker([20.67572202283851, -103.36834837390315], {         
    nombre: "Sky Wings",
    direccion : "Av. Chapultepec Sur 78, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/CsH1JMpsdtu/"
}))
tortas_Ahogadas.push(L.marker([20.678181604659837, -103.37196069666281], {         
    nombre: "Tortas Dany",
    direccion : "C. Justo Sierra s/n, Ladrón de Guevara",
    ticket: 100,
    link:"https://www.instagram.com/p/CsMIPK9tOgN/"
}))
bares.push(L.marker([20.676870516749887, -103.36736298873399], {         
    nombre: "La Tropicosa",
    direccion : "C. José María Morelos 1551, Col Americana",
    ticket: 350,
    link:"https://www.instagram.com/p/CsRulF0gPrM/"
}))
antojitos_Mexicanos.push(L.marker([20.652823786578136, -103.4320253511512], {         
    nombre: "Quesadillas Roger",
    direccion : "Av. Nicolás Copérnico, Moctezuma Pte.",
    ticket: 150,
    link:"https://www.instagram.com/p/CsWTlwHPdfW/"
}))
bbqY_ahumador.push(L.marker([20.647562841623536, -103.30068589667454], {         
    nombre: "Smoke House",
    direccion : "Av San Jacinto 1034, Jardines de la Paz",
    ticket: 220,
    link:"https://www.instagram.com/p/CvslymzOdO7/"
}))
cafeterias.push(L.marker([20.675227022144348, -103.36246643528365], {         
    nombre: "Makay Barra Café",
    direccion : "Av. Ignacio L Vallarta 1173-2, Col Americana",
    ticket: 150,
    link:"https://www.instagram.com/p/CseJ9yCMuLL/"
}))
hamburgeserias.push(L.marker([20.708073736453965, -103.41073032095345], {         
    nombre: "Shake Shack",
    direccion : "P.º de los Virreyes 45, Puerta de Hierro",
    ticket: 250,
    link:"https://www.instagram.com/p/Csjt-rrOUc-/"
}))
brunchs.push(L.marker([20.681253043333292, -103.37312681942242], {         
    nombre: "Peltre Cocina",
    direccion : "C. Reforma 1608, Ladrón de Guevara",
    ticket: 200,
    link:"https://www.instagram.com/p/Csl91_KObp0/"
}))
cocina_Oriental.push(L.marker([20.679823999359908, -103.39193765062693], {         
    nombre: "Kappo Ai",
    direccion : "Av. México 3053, Vallarta Nte.",
    ticket: 700,
    link:"https://www.instagram.com/p/Csovh4IPz54/"
}))
cocina_Oriental.push(L.marker([20.68392087257359, -103.39657816545693], {         
    nombre: "Thaiger",
    direccion : "Av Aztecas 270, Monraz",
    ticket: 250,
    link:"https://www.instagram.com/p/Csr5RdarxtT/"
}))
bares.push(L.marker([20.69809046738311, -103.38407766163206], {         
    nombre: "Galgo Speakeasy",
    direccion : "Av Pablo Neruda 3055, Providencia 4a. Secc",
    ticket: 350,
    link:"https://www.instagram.com/p/CsuBuQPpJ83/"
}))
bares.push(L.marker([20.702497085263033, -103.37846706560201], {         
    nombre: "Mestizo",
    direccion : "C. Ottawa 1581, Providencia",
    ticket: 300,
    link:"https://www.instagram.com/p/CswV4G2uXVd/"
}))
cocina_Oriental.push(L.marker([20.654732220704183, -103.40155699615802], {         
    nombre: "Muchang",
    direccion : "Av. Adolfo López Mateos Sur 2198, Cd del Sol",
    ticket: 300,
    link:"https://www.instagram.com/p/CxqvSOBOttL/"
}))
munchies.push(L.marker([20.64481240926409, -103.40109903529527], {         
    nombre: "Cuca y Lupe",
    direccion : "Av. Lapizlázuli 2984, Victoria",
    ticket: 100,
    link:"https://www.instagram.com/p/Cs1N991PKRp/"
}))
cortes_restauante.push(L.marker([20.654945547906916, -103.40133047339724], {         
    nombre: "Hacienda Canelos",
    direccion : "Av. Adolfo López Mateos Sur 2198, Cd del Sol",
    ticket: 400,
    link:"https://www.instagram.com/p/Cs4UbyCNCkD/"
}))
bbqY_ahumador.push(L.marker([20.687914623792302, -103.28896933527867], {         
    nombre: "El Humadero",
    direccion : "44270, Hacienda del Potrero 3054, Oblatos",
    ticket: 150,
    link:"https://www.instagram.com/p/Cs9grsoOhyx/"
}))
cortes_restauante.push(L.marker([20.727831884006033, -103.41602541940453], {         
    nombre: "Cortes Selectos República",
    direccion : "Anillo Perif. Nte. Manuel Gómez Morín 736, Santa Margarita1a Secc.",
    ticket: 350,
    link:"https://www.instagram.com/p/Cs_oC4rOEep/"
}))
cafeterias.push(L.marker([20.686787112781875, -103.38806112147552], {         
    nombre: "Alasú",
    direccion : "Av Pablo Casals 487, Providencia",
    ticket: 150,
    link:"https://www.instagram.com/p/CtCca0oJOZ0/"
}))
brunchs.push(L.marker([20.673292979219035, -103.363078073904], {         
    nombre: "Membrillo Cocina",
    direccion : "Libertad 1760, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/CtFIyWng4uW/"
}))
pizzerias.push(L.marker([20.667022753675568, -103.4042839194279], {         
    nombre: "Cola Blanca Chapalita",
    direccion : "Av Guadalupe 1292, Chapalita",
    ticket: 250,
    link:"https://www.instagram.com/p/CtH0fSAAYTi/"
}))
tortas_Ahogadas.push(L.marker([20.690719143307415, -103.37263839665806], {         
    nombre: "Tortas Ahogadas Colomos",
    direccion : "C. José María Vigil 1874, Ayuntamiento",
    ticket: 100,
    link:"https://www.instagram.com/p/CtM95B9NUsP/"
}))
marisquerias.push(L.marker([20.66721216794262, -103.36261659718092], {         
    nombre: "Tortas Ahogadas y Mariscos Rogelio",
    direccion : "C. Venezuela 665, Moderna",
    ticket: 100,
    link:"https://www.instagram.com/p/CtM95B9NUsP/"
}))
tortas_Ahogadas.push(L.marker([20.687944306337794, -103.37181300407484], {         
    nombre: "Ahogdas Sánchez",
    direccion : "Alfredo R. Plascencia 648, Ladron De Guevara",
    ticket: 100,
    link:"https://www.instagram.com/p/CtM95B9NUsP/"
}))
cortes_restauante.push(L.marker([20.689310606994592, -103.41817808872914], {         
    nombre: "Qué Pachó",
    direccion : "Av Naciones Unidas 4762, Jardines Universidad",
    ticket: 250,
    link:"https://www.instagram.com/p/CtPkUIIpGam/"
}))
hamburgeserias.push(L.marker([20.679018902776868, -103.39809305804289], {         
    nombre: "Prehistoric Land",
    direccion : "Av. México 3285, VallartaSaint Jorge",
    ticket: 250,
    link:"https://www.instagram.com/p/CtUf8Hzusx5"
}))
cafeterias.push(L.marker([20.674377606811056, -103.45117458465491], {         
    nombre: "Gutter Coffee Bread",
    direccion : "Calz de los Angeles 6945, Granja",
    ticket: 150,
    link:"https://www.instagram.com/p/CtXFok1JHi4/"
}))
munchies.push(L.marker([20.67410628337506, -103.37071685011541], {         
    nombre: "Sauce's",
    direccion : "Calle Marsella 159, Col Americana, Lafayette",
    ticket: 150,
    link:"https://www.instagram.com/p/C8dWUFEO_iE/"
}))
taquerias.push(L.marker([20.65673588000783, -103.36667915805157], {         
    nombre: "Taquería La Flor de Saguayo",
    direccion : "C. Fresno 1705, Del Fresno",
    ticket: 150,
    link:"https://www.instagram.com/p/CtcV9akLtIC/"
}))
loncheria.push(L.marker([20.6585203384221, -103.37120341943128], {         
    nombre: "Lonches Lisboa",
    direccion : "C. Fresno 2001, Del Fresno",
    ticket: 100,
    link:"https://www.instagram.com/p/C3rDE7nuFXy/"
}))
antojitos_Mexicanos.push(L.marker([20.655124976450967, -103.36286705805203], {         
    nombre: "Cenaduría Doña Rebe",
    direccion : "C. Fresno 1474, Morelos",
    ticket: 200,
    link:"https://www.instagram.com/p/CthhqIlLrGX/"
}))
comida_Argentina.push(L.marker([20.685071967767385, -103.3903710386196], {         
    nombre: "Empanadas Argentinas La Porteña",
    direccion : "Plaza Abaco, Av. Manuel Acuña 2952, Prados Providencia, 44670 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/CtkFoKjMuDd/"
}))
munchies.push(L.marker([20.68884260910558, -103.39558318131365], {         
    nombre: "Dulcería La Rosa",
    direccion : "Av Aztecas 3200, Prados Providencia",
    ticket: 100,
    link:"https://www.instagram.com/p/CtpCvx_NQop/"
}))
bares.push(L.marker([20.67010157583509, -103.34892798873645], {         
    nombre: "La Occidental",
    direccion : "C. Nueva Galicia 911, Centro",
    ticket: 200,
    link:"https://www.instagram.com/p/C1fxOGmuST0/"
}))
bares.push(L.marker([20.678913965843968, -103.36936489666283], {         
    nombre: "Bali Bar",
    direccion : "C. Justo Sierra 2041a, Ladrón de Guevara",
    ticket: 250,
    link:"https://www.instagram.com/p/CtzeUaMvncO/"
}))
cafeterias.push(L.marker([20.690891642392312, -103.41092591993254], {         
    nombre: "Rico Tostado House",
    direccion : "Av Naciones Unidas 7140-6",
    ticket: 250,
    link:"https://www.instagram.com/p/Ct1mlEWR3cM/"
}))
tortas_Ahogadas.push(L.marker([20.684558180967638, -103.32973978491175], {         
    nombre: "Tortas Ahogadas Rober's",
    direccion : "López Portillo 745, La Perla",
    ticket: 100,
    link:"https://www.instagram.com/p/Ct4XLgGOpXL/"
}))
tortas_Ahogadas.push(L.marker([20.685657676881767, -103.3737842580403], {         
    nombre: "Tortas y Tacos Los Gueros ",
    direccion : "C. Juan Álvarez 1824, Ladrón de Guevara",
    ticket: 100,
    link:"https://www.instagram.com/p/Ct4XLgGOpXL/"
}))
tortas_Ahogadas.push(L.marker([20.66584605047291, -103.3476510506323], {         
    nombre: "Tortas Ahogadas Don José el de la Bicicleta",
    direccion : "C. Mexicaltzingo 27, Mexicaltzingo",
    ticket: 100,
    link:"https://www.instagram.com/p/Ct4XLgGOpXL/"
}))
cocina_Oriental.push(L.marker([20.673805668938023, -103.36658571942523], {         
    nombre: "Momotabi",
    direccion : "Libertad 1916, Col Americana, Americana",
    ticket: 200,
    link:"https://www.instagram.com/p/Ct7QykwLC2M/"
}))
sushis.push(L.marker([20.68629741060214, -103.29409743527948], {         
    nombre: "Sukidai",
    direccion : "Av Artesanos 1080C, San Isidro Oblatos",
    ticket: 200,
    link:"https://www.instagram.com/p/Ct95caBp7EG/"
}))
antojitos_Mexicanos.push(L.marker([20.67105120037631, -103.36099891149699], {         
    nombre: "Gorditas Las Meneadas",
    direccion : "Av. de la Paz 1577, Col Americana",
    ticket: 150,
    link:"https://www.instagram.com/p/C2fQgjfug2r/"
}))
brunchs.push(L.marker([20.673808337094048, -103.36213469666463], {         
    nombre: "Leonela bistro",
    direccion : "C. Emeterio Robles Gil 247, Col Americana",
    ticket: 200,
    link:"https://www.instagram.com/p/C8pOFkfuegI/"
}))
cocina_Oriental.push(L.marker([20.692231193819772, -103.38779505010837], {         
    nombre: "Danzú",
    direccion : "C. José María Vigil 2991, Prados Providencia",
    ticket: 250,
    link:"https://www.instagram.com/p/CuFlkqyrgdV/"
}))
tortas_Ahogadas.push(L.marker([20.67563007176812, -103.38083281942463], {         
    nombre: "Ahogadas Magno",
    direccion : "Av. Ignacio L Vallarta 2440, Ladron De Guevara",
    ticket: 150,
    link:"https://www.instagram.com/p/CuHru7BrcE4/"
}))
pizzerias.push(L.marker([20.66579435486111, -103.3642244966678], {         
    nombre: "El Merodeador",
    direccion : "C. Venezuela 758, Moderna",
    ticket: 200,
    link:"https://www.instagram.com/p/CuNLgEvODoQ/"
}))
hamburgeseriasYhotdogs.push(L.marker([20.674665143327587, -103.36605291942514], {         
    nombre: "La Hamburguesería Central",
    direccion : "Libertad 1878, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/C07VS6HOL_o/"
}))
marisquerias.push(L.marker([20.640261916029953, -103.42142636547327], {         
    nombre: "El Niño Perdido",
    direccion : "C. Volcán Vesubio 4969, Colli Urbano",
    ticket: 250,
    link:"https://www.instagram.com/p/CuSb-NoreoB/"
}))
menuderias.push(L.marker([20.690364002242926, -103.3576453352778], {         
    nombre: "Menudería Cano",
    direccion : "C. José María Vigil 1039, Artesanos",
    ticket: 150,
    link:"https://www.instagram.com/p/CuUrCFFxjO5/"
}))
brunchs.push(L.marker([20.67751847102111, -103.35369575804366], {         
    nombre: "Jardín de Pichina",
    direccion : "C. José María Morelos 710, Centro",
    ticket: 250,
    link:"https://www.instagram.com/p/CuXcRhUPeZ3/"
}))
taquerias.push(L.marker([20.654844105319825, -103.42932738874242], {         
    nombre: "Tacos Joaquín",
    direccion : "Av. Tepeyac &, Playa Yelapa Nte. 985 Nte, Moctezuma",
    ticket: 150,
    link:"https://www.instagram.com/p/CuaHmaGM1Hh/"
}))
brunchs.push(L.marker([20.78127210346848, -103.46221196541921], {         
    nombre: "El Brunch",
    direccion : "Lat. Juan Gil Preciado 7050",
    ticket: 200,
    link:"https://www.instagram.com/p/Cucd7SbMzPr/"
}))
bares.push(L.marker([20.679150059349887, -103.32777915804301], {         
    nombre: "Mascusia",
    direccion : "Av. República 711, San Juan de Dios",
    ticket: 300,
    link:"https://www.instagram.com/p/CufBDv4PWWE/"
}))
hamburgeserias.push(L.marker([20.67177938096331, -103.38167761149663], {         
    nombre: "Soul Fried Chicken",
    direccion : "Francisco de Quevedo 183 A, Arcos Vallarta",
    ticket: 300,
    link:"https://www.instagram.com/p/Cuh3yQprH9E/"
}))
tortas_Ahogadas.push(L.marker([20.6673676208719, -103.34803265165938], {         
    nombre: "El Principe Heredero",
    direccion : "Epigmenio González 200, Mexicaltzingo",
    ticket: 100,
    link:"https://www.instagram.com/p/CumqlCEs2Gj/"
}))
tortas_Ahogadas.push(L.marker([20.663908393919186, -103.40361225011921], {         
    nombre: "Tortas Ahogadas Clemens",
    direccion : "Aurora 145, Chapalita Oriente",
    ticket: 100,
    link:"https://www.instagram.com/p/CumqlCEs2Gj/"
}))
cafeterias.push(L.marker([20.66327272437386, -103.4198213273588], {         
    nombre: "Café Providencia",
    direccion : "Av Guadalupe 4931, Jardines de Guadalupe",
    ticket: 250,
    link:"https://www.instagram.com/p/C5d9VP6uake/"
}))
marisquerias.push(L.marker([20.636547387758874, -103.38100305805946], {         
    nombre: "Rey de Barrio",
    direccion : "Isla Lesnos 3210, Jardines de La Cruz",
    ticket: 250,
    link:"https://www.instagram.com/p/CusM2mauHtr/"
}))
hotDogs.push(L.marker([20.67656342103185, -103.35869281942432], {         
    nombre: "Hot Dogs El Chino",
    direccion : "C. Escorza 95, Col Americana",
    ticket: 100,
    link:"https://www.instagram.com/p/Cuxs8PouZim/"
}))
taquerias.push(L.marker([20.650866419734015, -103.2837192506383], {         
    nombre: "Todo D Barbacoa",
    direccion : "Av. Manuel Román Alatorre 4012, Insurgentes",
    ticket: 150,
    link:"https://www.instagram.com/p/Cuzime5rejf/"
}))
munchies.push(L.marker([20.664309010677368, -103.4146357506329], {         
    nombre: "Frutas Genaro",
    direccion : "C. Organización 811 C, Cd de los Niños",
    ticket: 100,
    link:"https://www.instagram.com/p/Cu2UJfdra2n/"
}))
marisquerias.push(L.marker([20.662739511610773, -103.42859925804929], {         
    nombre: "Aguachiris",
    direccion : "Av Guadalupe 5347-local 14, Arcos de Guadalupe",
    ticket: 250,
    link:"https://www.instagram.com/p/Cu5D3vpsZXy/"
}))
cortes_restauante.push(L.marker([20.550776113282712, -103.44276038136697], {         
    nombre: "Bistro Victoria Roof",
    direccion : "Hidalgo 37, 45600 Santa Anita",
    ticket: 250,
    link:"https://www.instagram.com/p/Cu7nH6qvqgA/"
}))
pizzerias.push(L.marker([20.665985120336874, -103.43611111994213], {         
    nombre: "Pepitas Pizza",
    direccion : "Av. Piotr Ilich Tchaikovski 575, Arcos de Guadalupe",
    ticket: 200,
    link:"https://www.instagram.com/p/CvAqnQPuBsT/"
}))
loncheria.push(L.marker([20.670773460543483, -103.3528244733913], {         
    nombre: "Cremeria Atemajac",
    direccion : "Av 8 de Julio 502, Zona Centro",
    ticket: 100,
    link:"https://www.instagram.com/p/CvDKRYpO0tc/"
}))
cortes_restauante.push(L.marker([20.625569405754568, -103.24296370512624], {         
    nombre: "Ágora Restaurante",
    direccion : "33 morelos, Tonalá Centro",
    ticket: 200,
    link:"https://www.instagram.com/p/CvIlG2JMp5u/"
}))
antojitos_Mexicanos.push(L.marker([20.685589372680884, -103.3683241273502], {         
    nombre: "Fonda Mariquita",
    direccion : "C. Andrés Terán 525-Local 98 y 101, Santa Teresita",
    ticket: 150,
    link:"https://www.instagram.com/p/CvKsZ4iAIFA/"
}))
taquerias.push(L.marker([20.65795859211542, -103.35995328132555], {         
    nombre: "Birrería El Chino",
    direccion : "Cardenal 1370, Morelos",
    ticket: 150,
    link:"https://www.instagram.com/p/CvNp_6BOWqG/"
}))
bares.push(L.marker([20.676272263087586, -103.36326747390284], {         
    nombre: "Vietnam",
    direccion : "C. Pedro Moreno 1296, Col Americana",
    ticket: 300,
    link:"https://www.instagram.com/p/CvSiBanrXoB/"
}))
pizzerias.push(L.marker([20.689499429733903, -103.38435459532012], {         
    nombre: "Rúcola",
    direccion : "Jesús García No. 2722 Cruce con Rubén Darío",
    ticket: 250,
    link:"https://www.instagram.com/p/CvYAFlMrLzq/"
}))
hamburgeserias.push(L.marker([20.663617558746527, -103.40196202735862], {         
    nombre: "Voodoo Burgers",
    direccion : "Av. Tepeyac 745, Chapalita",
    ticket: 250,
    link:"https://www.instagram.com/p/CvdGtaAuCNU/"
}))

marisquerias.push(L.marker([20.674698294981525, -103.37045141942515], {         
    nombre: "Kalamar RAB",
    direccion : "C. Manuel López Cotilla 1539, Col Americana",
    ticket: 300,
    link:"https://www.instagram.com/p/CvfrPbRs2yO/"
}))
taquerias.push(L.marker([20.667207267968575, -103.36720262735733], {         
    nombre: "Tacos Don Luis",
    direccion : "C. Progreso 612-Local 10, Moderna",
    ticket: 150,
    link:"https://www.instagram.com/p/Cvn32oDOK3X/"
}))
marisquerias.push(L.marker([20.679310258203913, -103.32185159666257], {         
    nombre: "Siete y Medio de Paco",
    direccion : "C. Industria 1099, San Juan Bosco",
    ticket: 300,
    link:"https://www.instagram.com/p/C6Hf7gmOuux/"
}))
taquerias.push(L.marker([20.630094110184327, -103.42247895806175], {         
    nombre: "Los Clásicos Pa Taquear",
    direccion : "Av. Nicolás Copérnico 3959, Arboledas",
    ticket: 200,
    link:"https://www.instagram.com/p/C0QCjy8OYfc/"
}))
marisquerias.push(L.marker([20.66996555335101, -103.42023979666607], {         
    nombre: "La Xhilacata",
    direccion : "45038, Lomas del Seminario",
    ticket: 250,
    link:"https://www.instagram.com/p/Cv21AiDPCwi/"
}))
hotDogs.push(L.marker([20.660361149378215, -103.42373387339538], {         
    nombre: "Hot Dogs Los Guasaveños",
    direccion : "Av. Patria 886, Jardines del Tepeyac",
    ticket: 150,
    link:"https://www.instagram.com/p/Cv55ROlsLqa/"
}))
antojitos_Mexicanos.push(L.marker([20.729022048414805, -103.51619535836933], {         
    nombre: "Tamales La Motito",
    direccion : "Carr. a Nogales Km. 15, Carr. Guadalajara - Tepic",
    ticket: 150,
    link:"https://www.instagram.com/p/Cv7pqjfRoYG/"
}))
antojitos_Mexicanos.push(L.marker([20.549198929546474, -103.44179752588337], {         
    nombre: "Molletes Socorro",
    direccion : "Av. Ramon Corona 3, 45600",
    ticket: 100,
    link:"https://www.instagram.com/p/Cv_BxXbsC_z/"
}))
taquerias.push(L.marker([20.727718787924807, -103.40299429999999], {         
    nombre: "Taquería Don José",
    direccion : "Col San Francisco Toribio de Motolinia 703, San Francisco",
    ticket: 150,
    link:"https://www.instagram.com/p/CwGtPO8q8I-/"
}))
taquerias.push(L.marker([20.67640876254443, -103.37040420407918], {         
    nombre: "Jose Cande Chicharronería",
    direccion : "C. José María Morelos 1739, Col Americana",
    ticket: 200,
    link:"https://www.instagram.com/p/CwIq06nK2vQ/"
}))
taquerias.push(L.marker([20.553031387527273, -103.44518218878154], {         
    nombre: "Tacos El Willy",
    direccion : "C. Manuel Acuña 189",
    ticket: 100,
    link:"https://www.instagram.com/p/CwLf8-DKIrU/"
}))
bares.push(L.marker([20.678515958831415, -103.40278600407846], {         
    nombre: "Maldito Consuelo",
    direccion : "C. Leñadores 225, Villas de San Javier",
    ticket: 250,
    link:"https://www.instagram.com/p/CwOCI5OKGRJ/"
}))
comida_Italiana.push(L.marker([20.636699096838264, -103.38975043478464], {         
    nombre: "Roma Trattoria",
    direccion : "Av Isla Pantenaria 2403, Jardines del Sur",
    ticket: 250,
    link:"https://www.instagram.com/p/CwTMJ4KKehw/"
}))
hoteles.push(L.marker([20.29255603615596, -103.20212242750041], {         
    nombre: "Hotel Villa Montecarlo",
    direccion : "Hidalgo 296, Lourdes",
    ticket: 450,
    link:"https://www.instagram.com/p/CwVUsqwqoPJ/"
}))
marisquerias.push(L.marker([20.287627628195697, -103.32478602750237], {         
    nombre: "Iguana de Piedra",
    direccion : "Carr Jocotepec-chapala 636",
    ticket: 400,
    link:"https://www.instagram.com/p/CwawV5JKyo_/"
}))
cafeterias.push(L.marker([20.674388049356978, -103.37175483477037], {         
    nombre: "Amelie Cafetería",
    direccion : "C. Gral. San Martín 171, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/C09o8UZuJH2/"
}))
taquerias.push(L.marker([20.67797585810917, -103.43492010561995], {         
    nombre: "Tacos Los Xolos",
    direccion : "Independencia 256, Sta María del Pueblito",
    ticket: 200,
    link:"https://www.instagram.com/p/CwjBeiKKnNd/"
}))
bares.push(L.marker([20.67846420669456, -103.34451635907068], {         
    nombre: "Cantina La Fuente",
    direccion : "C. Pino Suárez 78, Zona Centro",
    ticket: 250,
    link:"https://www.instagram.com/p/CwltNycqkbY/"
}))
hamburgeserias.push(L.marker([20.58750448955194, -103.44669633531741], {         
    nombre: "La Hamburgueria",
    direccion : "Av. Adolfo López Mateos Sur 1710, Santa Isabel",
    ticket: 250,
    link:"https://www.instagram.com/p/CwnyUvhKMz3/"
}))
munchies.push(L.marker([20.737735193621045, -103.36596821198503], {         
    nombre: "Crazy Cono",
    direccion : "P.º de Los Alerces 1273, Tabachines",
    ticket: 150,
    link:"https://www.instagram.com/p/CwoNHGVKhT2/"
}))
antojitos_Mexicanos.push(L.marker([20.418357164126245, -103.23500340469181], {         
    nombre: "Los Milagros de Dalila",
    direccion : "Carretera, Carr. Guadalajara - Chapala km 25",
    ticket: 200,
    link:"https://www.instagram.com/p/Cwp5-KtO8Mi/"
}))
bares.push(L.marker([20.201265532506486, -103.30910932063294], {         
    nombre: "Vampiritos de Soyotlán",
    direccion : "México 15 362",
    ticket: 150,
    link:"https://www.instagram.com/p/Cwv3kJxO0sQ/"
}))
brunchs.push(L.marker([20.713389873756704, -103.41123447440229], {         
    nombre: "Sunday Brunch Andares Lounge",
    direccion : "Blvrd Puerta de Hierro 5065",
    ticket: 800,
    link:"https://www.instagram.com/p/CwsuCJ2usPG/"
}))
taquerias.push(L.marker([20.550535681986148, -103.44471735464174], {         
    nombre: "Tacos El Joven",
    direccion : "45600, Ocampo 127",
    ticket: 100,
    link:"https://www.instagram.com/p/Cwynr8JuRJD/"
}))
bbqY_ahumador.push(L.marker([20.67568901690811, -103.36670425062853], {         
    nombre: "Gallo Cervecero",
    direccion : "C. Progreso 79, Col Americana",
    ticket: 250,
    link:"https://www.instagram.com/p/Cw0rGocONGZ/"
}))
bares.push(L.marker([20.68028989682722, -103.39159236597182], {         
    nombre: "Distrito Social Room",
    direccion : "Av. México 3040-local 206, Juan Manuel",
    ticket: 600,
    link:"https://www.instagram.com/p/Cw50rWIuIjZ/"
}))
marisquerias.push(L.marker([20.676944772566326, -103.3731378808046], {         
    nombre: "El Mediterráneo MX",
    direccion : "C. José María Morelos 1854, Ladrón de Guevara",
    ticket: 250,
    link:"https://www.instagram.com/p/Cw8Zc6SOBwq/"
}))
bares.push(L.marker([20.665928474744938, -103.34685761201274], {         
    nombre: "Cantina la Sin Rival",
    direccion : "Calz Independencia Sur 699, Barragán Hernández",
    ticket: 250,
    link:"https://www.instagram.com/p/CxBmiZRurST/"
}))
hamburgeserias.push(L.marker([20.67624627719303, -103.38124157338923], {         
    nombre: "Epic Burger",
    direccion : "44130, Arcos Vallarta",
    ticket: 250,
    link:"https://www.instagram.com/p/CxEHXbVOprg/"
}))
tortas_Ahogadas.push(L.marker([20.649724093637758, -103.43307565012469], {         
    nombre: "Tortas Ahogadas Chisco",
    direccion : "Av. Nicolás Copérnico 1528-B, Moctezuma Pte.",
    ticket: 100,
    link:"https://www.instagram.com/p/CxTWlemuIds/"
}))
brunchs.push(L.marker([20.713272819823743, -103.40632815567771], {         
    nombre: "Hyatt regency buffet",
    direccion : "Blvrd Puerta de Hierro 5065, 45116 Zapopan, Jal.",
    ticket: 799,
    link:"https://www.instagram.com/p/C3_h6pguy38/"
}))
antojitos_Mexicanos.push(L.marker([20.679632832484508, -103.33930714741854], {         
    nombre: "lonchería los 3 alegres compadres",
    direccion : "Calz. Independencia Norte 282, Zona Centro, 44360 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/CxMRwsMOEc9/"
}))
antojitos_Mexicanos.push(L.marker([20.67402947421788, -103.36693819159547], {         
    nombre: "La tapia",
    direccion : "C. Manuel López Cotilla 1386, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/CxYSOqaupet/"
}))
taquerias.push(L.marker([20.668065562843015, -103.36329433392491], {         
    nombre: "Tacos de guisado San nicolás",
    direccion : "C. Venezuela 593, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/CxgCteOOp5p/"
}))
bares.push(L.marker([20.63519991018058, -103.41475397625511], {         
    nombre: "Lisas y rayas",
    direccion : "Av De La Calma 3251-PA, La Calma, 45070 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/Cxi6U6nucCN/"
}))
taquerias.push(L.marker([20.670362842037253, -103.36309589408248], {         
    nombre: "Carnes asadas Don josé",
    direccion : "Calle Argentina 595, Americana, 44160 Guadalajara, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/CxnvW6duntn/"
}))
taquerias.push(L.marker([20.74659961003407, -103.38572514741665], {         
    nombre: "Tacos de humo",
    direccion : "45132, San José del Bajío, 45132 Zapopan, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/Cxs5JWhOJiL/"
}))
bares.push(L.marker([20.673998988109265, -103.3622688050894], {         
    nombre: "Cantina el Desestrés",
    direccion : "C. Manuel López Cotilla 1145, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/Cxv3bazuQe0/"
}))
bbqY_ahumador.push(L.marker([20.668387217396045, -103.40939308974814], {         
    nombre: "Parrilla con artigas",
    direccion : "Av. Niño Obrero 778b, Jardín de San Ignacio, 45040 Zapopan, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C56nHJwOJj3/"
}))
dulceria.push(L.marker([20.56922423334692, -103.45219597625692], {         
    nombre: "Oma pop",
    direccion : "Av. Punto Sur 235-local 136, 44645 Los Gavilanes, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/Cxy7P1gOVfR/"
}))
brunchs.push(L.marker([20.66576555773518, -103.40734411349146], {         
    nombre: "Artemisa",
    direccion : "Av Guadalupe 1614-B, Chapalita, Chapalita Oriente, 45040 Zapopan, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/Cx03uITOWP4/"
}))
antojitos_Mexicanos.push(L.marker([20.67469386319522, -103.36277153392476], {         
    nombre: "balva Bistró",
    direccion : "Av. Ignacio L Vallarta 1171, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/Cx6M2wSO2uY/"
}))

taquerias.push(L.marker([20.667792024888435, -103.36611989159569], {         
    nombre: "Tres de barbacha",
    direccion : "Calle Vidrio 1853, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/Cx8WeBRuIII/"
}))
brunchs.push(L.marker([20.66922378453005, -103.40959624741885], {         
    nombre: "The grand café",
    direccion : "Av. Niño Obrero 664 A, Jardín de San Ignacio, 45040 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/CyBczWLOgkN/"
}))
bares.push(L.marker([20.67720871118643, -103.39111410508926], {         
    nombre: "Barra B",
    direccion : "Diag. Golfo de Cortés 2981, Vallarta Nte., 44690 Guadalajara, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/CyB8Il4u2DE/"
}))
marisquerias.push(L.marker([20.739754086421208, -103.43043118974614], {         
    nombre: "Sabrositos del mar zona real",
    direccion : "Av. del Servidor Público 1368, colonia plaza real, 45138 Zapopan, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/CyMIOEdu7vu/"
}))
antojitos_Mexicanos.push(L.marker([20.693075951386977, -103.38675329159494], {         
    nombre: "El mineral",
    direccion : "Av Terranova 1079, Lomas de Providencia, 44630 Guadalajara, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/CyT6kwCu9ba/"
}))
marisquerias.push(L.marker([20.661037779451465, -103.45463267810193], {         
    nombre: "Lobo lobito",
    direccion : "Las Torres 5706-C, Colonia San Rodolfo, 45010 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C0kMFC8OhBY/"
}))
brunchs.push(L.marker([20.670195021392466, -103.3733367339249], {         
    nombre: "Casa Neira",
    direccion : "44120 Union 339, Col Americana, Americana, 44120 Guadalajara, Jal.",
    ticket: 220,
    link:"https://www.instagram.com/p/CyYy_iCuxPf/"
}))
gusguerias.push(L.marker([20.70769274545157, -103.4158178204299], {         
    nombre: "Blazz California burritos",
    direccion : "Av. Paseo Royal Country 4692, Puerta de Hierro, 45136 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/C7KbjmWuhAX/"
}))
bares.push(L.marker([20.726393936048588, -103.4304401050879], {         
    nombre: "Escenik teatro bar",
    direccion : "Av. Central Guillermo Gonzalez Camarena 375, Puerta del Valle, 45136 Zapopan, Jal.",
    ticket: 700,
    link:"https://www.instagram.com/p/Cyl60_fuHrV/"
}))
taquerias.push(L.marker([20.64659863639651, -103.43258821858409], {         
    nombre: "Asadas el concho",
    direccion : "C. Volcan de Baru 1887b, Colli Urbano, 45070 Zapopan, Jal.",
    ticket: 160,
    link:"https://www.instagram.com/p/CymbN1ROurs/"
}))
bbqY_ahumador.push(L.marker([20.709687228283798, -103.41324226275914], {         
    nombre: "Lupes BBQ",
    direccion : "P.º Andares, Puerta de Hierro, 45116 Zapopan, Jal.",
    ticket: 320,
    link:"https://www.instagram.com/p/CyoibJZOyNW/"
}))
cafeterias.push(L.marker([20.686134986723125, -103.39009954741834], {         
    nombre: "Alasú",
    direccion : "Av Pablo Casals 487, Providencia, 44100 Guadalajara, Jal.",
    ticket: 260,
    link:"https://www.instagram.com/p/Cyq3rCquF-h/"
}))
taquerias.push(L.marker([20.63512657285336, -103.31677165320278], {         
    nombre: "Tacos de barbacoa Mickey",
    direccion : "Av. Marcos Montero Ruiz 891, Alamo Lomas, 45560 San Pedro Tlaquepaque, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/CytS9QrOK7i/"
}))

pizzerias.push(L.marker([20.673994550158053, -103.36904758974799], {         
    nombre: "PZA pizzería",
    direccion : "C. Manuel López Cotilla 1468, Col Americana, Lafayette, 44160 Guadalajara, Jal.",
    ticket: 125,
    link:"https://www.instagram.com/p/C0ua5qAO-Rb/"
}))
brunchs.push(L.marker([20.672577903766392, -103.3614926339248], {         
    nombre: "Mina la antigua",
    direccion : "C. Nuño de Guzmán 310, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 220,
    link:"https://www.instagram.com/p/Cy0_LF6O4ek/"
}))
alitas.push(L.marker([20.668053492995366, -103.36885554741889], {         
    nombre: "Boneless from Helheim",
    direccion : "Av. Chapultepec Sur 480, Col Americana, Obrera, 44160 Guadalajara, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/Cy1aRd0O51Q/"
}))
taquerias.push(L.marker([20.674358736004773, -103.39397132043081], {         
    nombre: "taquería Corpar",
    direccion : "Av. Ignacio L Vallarta 3203, Vallarta Poniente, 44110 Guadalajara, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/C1DfMvQuzrM/"
}))
cocina_Oriental.push(L.marker([20.70946568614632, -103.41358354741766], {         
    nombre: "Ssam andares",
    direccion : "P.º Andares 5278, Puerta de Hierro, 45116 Zapopan, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/Cy9DbebOtW8/"
}))
pizzerias.push(L.marker([20.67774902353924, -103.36680616276001], {         
    nombre: "Pizzería de barrio",
    direccion : "C. Justo Sierra 1875, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 220,
    link:"index.html"
}))
antojitos_Mexicanos.push(L.marker([20.68444980251566, -103.36915890508911], {         
    nombre: "Birria el torito",
    direccion : "Av. Manuel Acuña 1542, Villaseñor, 44600 Guadalajara, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/Cy_UoMHueKR/"
}))
cocina_Autor.push(L.marker([20.697976617320965, -103.37292006275942], {         
    nombre: "Veneno.",
    direccion : "Av. de las Américas #1254, Country Club, 44610 Guadalajara, Jal.",
    ticket: 480,
    link:"https://www.instagram.com/p/CzCRumEu9Rp/"
}))
cocina_Oriental.push(L.marker([20.677825459466185, -103.36953336268886], {         
    nombre: "Jalitos",
    direccion : "C. Justo Sierra 2041, Ladrón de Guevara, Lafayette, 44600 Guadalajara, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/CzCumkVOJGN/"
}))
marisquerias.push(L.marker([20.753296538611234, -103.442469805015], {         
    nombre: "Aquí te la curo",
    direccion : "Av. Base Aerea 1004-A, 45138 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/CzHbGD9uIKk/"
}))
gusguerias.push(L.marker([20.71958461451, -103.32009354734429], {         
    nombre: "D'frito",
    direccion : "Calle Teodoro Flores 3262, Ricardo Flores Magón, 44240 Guadalajara, Jal.",
    ticket: 60,
    link:"https://www.instagram.com/p/CzKD3LHugyu/"
}))
taquerias.push(L.marker([20.676396225776582, -103.3586800031662], {         
    nombre: "Barbacoa Arthuro los de antaño",
    direccion : "C. José María Morelos 957, Col Americana, Centro, 44100 Guadalajara, Jal.",
    ticket: 80,
    link:"https://www.instagram.com/p/CzMIaXsOu81/"
}))
gusguerias.push(L.marker([20.674728038974383, -103.4303291320021], {         
    nombre: "Sauce's",
    direccion : "C. José María Morelos 957, Col Americana, Centro, 44100 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C8dWUFEO_iE/"
}))
brunchs.push(L.marker([20.68701417111262, -103.36975114919623], {         
    nombre: "El desayunito",
    direccion : "C. Pedro Antonio Buzeta 625-local 23, Santa Teresita, 44600 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C0hJB2-uZea/"
}))
antojitos_Mexicanos.push(L.marker([20.62432578186523, -103.24565677618276], {         
    nombre: "Tortería bravo",
    direccion : "Santos Degollado, Av Tonalá 22c, 45400 Tonalá, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/CzRbX8jOpz3/"
}))
hamburgeserias.push(L.marker([20.72852727688045, -103.47020793385148], {         
    nombre: "Happy Burger",
    direccion : "Solares Residencial, 45019 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/CzUI91GOMbc/"
}))
taquerias.push(L.marker([20.739741818414032, -103.44867406083615], {         
    nombre: "Rancho 62",
    direccion : "Av. Aviación 4572-local 7, Real III, 45136 Zapopan, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C41mn_8OwHc/"
}))
cortes_restauante.push(L.marker([20.64520715913823, -103.38311380316706], {         
    nombre: "La lupe cocina de brasa",
    direccion : "Av Cruz del Sur 2655, Jardines de La Cruz, 44950 Guadalajara, Jal.",
    ticket: 320,
    link:"https://www.instagram.com/p/C2DLEuYuLd1/"
}))
brunchs.push(L.marker([20.63979494385661, -103.42365751851065], {         
    nombre: "Ginger root",
    direccion : "C. Felipe Ruvalcaba 5235, Paseos del Sol, 45079 Zapopan, Jal.",
    ticket: 260,
    link:"https://www.instagram.com/p/CzZCwnVuyyb/"
}))
marisquerias.push(L.marker([20.667735201720596, -103.3680543338533], {         
    nombre: "Puerto calypso",
    direccion : "Calle Vidrio 1979, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/CzcCAoPOxXV/"
}))
taquerias.push(L.marker([20.66880115531144, -103.42955737618153], {         
    nombre: "El pelón taquería",
    direccion : "45030, C. Millet 375, La Estancia, 45030 Zapopan, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/CzfHIH8u1g0/"
}))
marisquerias.push(L.marker([20.668890646696138, -103.39167226083815], {         
    nombre: "Mariscos Pepe",
    direccion : "Misiones 599, Jardines de Los Arcos, 44500 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/CzhLUDtu-eY/"
}))
postres.push(L.marker([20.661671182791277, -103.4063551050176], {         
    nombre: "Pastelería Postrevia",
    direccion : "Av Cubilete 781b, Chapalita Sur, 45040 Guadalajara, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/Czjm2YpOypE/"
}))
marisquerias.push(L.marker([20.6692584313965, -103.42640137618157], {         
    nombre: "Pezcallito",
    direccion : "Av. Ludwig Van Beethoven 5104, La Estancia, 45030 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/CzmVZ2duulU/"
}))
marisquerias.push(L.marker([20.635624812144027, -103.39772417618249], {         
    nombre: "Cocos locos ",
    direccion : "Av Cruz del Sur 3117, Lomas de La Victoria, 45607 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C8DDI7YuziT/"
}))
brunchs.push(L.marker([20.69223323633403, -103.39104120501675], {         
    nombre: "Templo bonsai",
    direccion : "C. José María Vigil 3155, Prados Providencia, 44670 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/CzrEGH5uYi2/"
}))
marisquerias.push(L.marker([20.709755750228993, -103.31039464734444], {         
    nombre: "El ke dijimos",
    direccion : "Av. Belisario Domínguez 2654, La Esperanza (La Federacha ), 44300 Guadalajara, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/CzwqQLBu5zl/"
}))
postres.push(L.marker([20.674139884407747, -103.35591359152482], {         
    nombre: "Churros la bombilla",
    direccion : "C. Manuel López Cotilla 751-A, Col Americana, Moderna, 44100 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/Czzn6Iau_d1/"
}))
bares.push(L.marker([20.683196639822725, -103.34538484734531], {         
    nombre: "Bar la Cava",
    direccion : "C. Ignacio Herrera y Cairo 258, Centro Barranquitas, 44200 Guadalajara, Jal.",
    ticket: 220,
    link:"https://www.instagram.com/p/Cz4X7SjuIfy/"
}))
cortes_restauante.push(L.marker([20.64828083257934, -103.4006438185104], {         
    nombre: "Donde hubo fuego",
    direccion : "Av. Obsidiana 2693, Victoria, 45089 Zapopan, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/Cz67V0GuuE9/"
}))
taquerias.push(L.marker([19.09891910073124, -104.32282251855226], {         
    nombre: "taqueria la sonrisa",
    direccion : "Océano Atlántico 788, Col del Mar, 28218 Manzanillo, Col.",
    ticket: 150,
    link:"https://www.instagram.com/p/Cz97be4uG0v/"
}))
cortes_restauante.push(L.marker([20.67426523958661, -103.34735357618142], {         
    nombre: "Altezza cocina",
    direccion : "C. Manuel López Cotilla 285-Piso 10, Zona Centro, 44100 Guadalajara, Jal.",
    ticket: 380,
    link:"https://www.instagram.com/p/C0AE8V8ODkZ/"
}))
brunchs.push(L.marker([20.669889798301245, -103.37006956083809], {         
    nombre: "Bigol bagel",
    direccion : "Calle Efraín González Luna 2083, Col Americana, Obrera, 44150 Guadalajara, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/C0E07I5uuXt/"
}))
antojitos_Mexicanos.push(L.marker([20.713856263776023, -103.3846785896727], {         
    nombre: "Burros y tortas El rey",
    direccion : "Av. de las Américas, Altamira, 45160 Zapopan, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/C0HoQBxuV9n/"
}))
sushis.push(L.marker([20.73838335572564, -103.4490302761796], {         
    nombre: "Ukiyo roll",
    direccion : "Av. Aviación 4368-14B, Jardín Real, 45136 Zapopan, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C0KYtebOc1r/"
}))
marisquerias.push(L.marker([20.627776377993285, -103.39032186269033], {         
    nombre: "Mariscos el marcial",
    direccion : "Av. Patria 2936, Villa Guerrero, 44987 Guadalajara, Jal.",
    ticket: 220,
    link:"https://www.instagram.com/p/C0NDIYWO0m1/"
}))
brunchs.push(L.marker([20.669322560931302, -103.42645757618153], {         
    nombre: "Buen taco ",
    direccion : "Av. Ludwig Van Beethoven 5104-A, La Estancia, 45030 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/C0RuO21u0Sy/"
}))
gusguerias.push(L.marker([20.641640582715425, -103.39125571851059], {         
    nombre: "Monkey papas",
    direccion : "Av. Lapizlázuli 2545-8, Bosques de La Victoria, 44580 Guadalajara, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C0Sh1NiOwTT/"
}))
marisquerias.push(L.marker([20.66522739739236, -103.40759983200238], {         
    nombre: "Sardinilla",
    direccion : "Av Guadalupe 1621-Local G2, Chapalita Oriente, 45040 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/C0UpZmvOQS0/"
}))
cafeterias.push(L.marker([20.676279712538538, -103.36228447803231], {         
    nombre: "Remedios by poison",
    direccion : "C. Emeterio Robles Gil 3, Col Americana, Americana, 44200 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C0b_dHMOPVs/"
}))
marisquerias.push(L.marker([20.603317961167928, -103.24467329153144], {         
    nombre: "Pinchis Cantaritos ALV",
    direccion : "Carr. libre a Zapotlanejo 287-A, La Gigantera, 45420 Tonalá, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C0PUJegO-z8/"
}))
gusguerias.push(L.marker([20.67478826634587, -103.43020038596971], {         
    nombre: "Sauces",
    direccion : "Walmart de San Isidro: Zapopan Industrial Nte., 45132 Zapopan, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C0XsTEaOIys/"
}))
brunchs.push(L.marker([20.692193089371045, -103.39097683200337], {         
    nombre: "Templo bonsai",
    direccion : "C. Jesus Romero Flores 685, Jardines Alcalde, 44298 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C0aSN89ucGC/"
}))
cortes_restauante.push(L.marker([20.639746925438622, -103.3153864185134], {         
    nombre: "Agave Tlaquepaque ",
    direccion : "Independencia 280, Centro, Tlaquepaque, Jalisco.",
    ticket: 300,
    link:"https://www.instagram.com/p/C0fADuguqO9/"
}))
antojitos_Mexicanos.push(L.marker([20.705907697694208, -103.33155407618383], {         
    nombre: "Cenaduria Angelica",
    direccion : "Calle Monte Carmelo 226, Independencia, 44379 Guadalajara, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/C0iLkiDuwag/"
}))
marisquerias.push(L.marker([20.687399470500697, -103.42413403200348], {         
    nombre: "Rozmar",
    direccion : "Av Naciones Unidas 5279-local 8, Jardines Universidad, 45110 Zapopan, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C0msUHruweh/"
}))
taquerias.push(L.marker([20.70000224175619, -103.32682072221812], {         
    nombre: "Ahumaditos",
    direccion : "Sierra Nevada 1806, Monumental, 44320 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C9JIjGPOWbI/"
}))
antojitos_Mexicanos.push(L.marker([220.680112664238766, -103.34900741337859], {         
    nombre: "Menuderia Martha ",
    direccion : "mercado corona 44100 local D09 44100, Guadalajara, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C0zEqI3OEQk/"
}))
bbqY_ahumador.push(L.marker([20.703056837507802, -103.34168147618392], {         
    nombre: "Cerdos Salvajes ",
    direccion : "Av. Cvln. División del Nte. 696, Jardines Alcalde, 44298 Guadalajara, Ja",
    ticket: 250,
    link:"https://www.instagram.com/p/C00C2xSul2L/"
}))
gusguerias.push(L.marker([20.757619461519777, -103.40037127618234], {         
    nombre: "Bionicos Alma",
    direccion : "Valdepeñas a lado de los Tacos Arandas y sobre Eutimio Pinzón afuera de los Asaderos Los Espinos.",
    ticket: 160,
    link:"https://www.instagram.com/p/C02ntn8uuOv/"
}))
brunchs.push(L.marker([20.642857281108363, -103.38785320502186], {         
    nombre: "Buffet Casa macaria",
    direccion : "Av Cruz del Sur 2964, Bosques de La Victoria, 44540 Guadalajara, Jal.",
    ticket: 240,
    link:"https://www.instagram.com/p/C1ABG8nuNPU/"
}))
taquerias.push(L.marker([20.642857281108363, -103.38785320502186], {         
    nombre: "Tripitas los Panchos",
    direccion : "Av Cruz del Sur 2964, Bosques de La Victoria, 44540 Guadalajara, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/C1GDYWZOw2L/"
}))
taquerias.push(L.marker([20.678238173462862, -103.34918630502088], {         
    nombre: "Tacos el oasis",
    direccion : "Calz Independencia Nte 503, Zona Centro, 44100 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C1Nw4lpOJCB/"
}))
antojitos_Mexicanos.push(L.marker([20.68898486798244, -103.29392802036523], {         
    nombre: "Los Guerazos de Oblatos",
    direccion : "Av Joaquín Amaro 1267A, Balcones de Oblatos, 44720 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C1SUyzGuutH/"
}))
taquerias.push(L.marker([20.68770775884971, -103.29398215898642], {         
    nombre: "Los de bisteck con papas",
    direccion : "Av Joaquín Amaro 1214, Balcones de Oblatos, 44720 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C1Vg3JjOTBS/"
}))
panaderias.push(L.marker([20.678413856524262, -103.43620921067652], {         
    nombre: "Cocol Panaderia",
    direccion : "Independencia 196, Sta María del Pueblito, 45018 Zapopan, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C1aAv2NuWMG/"
}))
bares.push(L.marker([20.68770775884971, -103.29398215898642], {         
    nombre: "Bar morelias",
    direccion : "Epigmenio González 1132, Mexicaltzingo, 44180 Guadalajara, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/C1k7BsLOEMe/"
}))
panaderias.push(L.marker([20.671366281554853, -103.3461152473487], {         
    nombre: "Panaderia Delicius",
    direccion : "Calle Pedro loza 230 Corona 393, Alcalde 295, Centro, 44100 Guadalajara, Jal.",
    ticket: 60,
    link:"https://www.instagram.com/p/C1mreHWO42w/"
}))
antojitos_Mexicanos.push(L.marker([20.673117441105262, -103.36837366269333], {         
    nombre: "Cubil Latino",
    direccion : "Av. Chapultepec Sur 201, Col Americana, Americana, 44150 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C1vR3AlurXj/"
}))
taquerias.push(L.marker([20.698115144380566, -103.33219882036497], {         
    nombre: "Tacos Gigantes el Franco",
    direccion : "Av. Cvln. División del Nte. 85, Independencia, 44290 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C15jEpzuP_-/"
}))
bares.push(L.marker([20.696545292441087, -103.37905729152882], {         
    nombre: "Gaston ",
    direccion : "C. Colomos 2546, Italia Providencia, 44630 Guadalajara, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C18HPIpOv9x/"
}))
taquerias.push(L.marker([20.704070100128746, -103.31844457803716], {         
    nombre: "Tacos Nano ",
    direccion : "C. Mesa Central 411-413, Colonia Belisario Domínguez, 44320 Guadalajara, Jal.",
    ticket: 80,
    link:"https://www.instagram.com/p/C-G8aDPu3jD/"
}))
comida_Thailandesa.push(L.marker([20.662974654520003, -103.40815374920224], {         
    nombre: "Siam Thai",
    direccion : "Parque Juan Diego 4015-Local 2, Chapalita, 44500 Guadalajara, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/C2IkvKJOalA/"
}))
panaderias.push(L.marker([20.68516817628377, -103.37177256269304], {         
    nombre: "Pethers Panaderia",
    direccion : "C. Juan Álvarez 1695, Santa Teresita, 44600 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C2nRg3zuwMS/"
}))
cocina_Oriental.push(L.marker([20.712851630049485, -103.39327684549426], {         
    nombre: "Jukino",
    direccion : "Calle Eva Briseño 682, Santa Fe, 45168 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C2LIcA_uyf9/"
}))
antojitos_Mexicanos.push(L.marker([20.70776659523126, -103.45845656269235], {         
    nombre: "Carnitas la Esperanza",
    direccion : "Av. Aviación 29, San Juan de Ocotán, 45019 Zapopan, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C2NgOOsu-0z/"
}))
cortes_restauante.push(L.marker([20.63963510490862, -103.31527747618576], {         
    nombre: "Hacienda Canelos ",
    direccion : "Calle Independencia 270, Centro, 45500 San Pedro Tlaquepaque, Jal.",
    ticket: 500,
    link:"https://www.instagram.com/p/C2QSei_S3iL/"
}))
pizzerias.push(L.marker([20.63963510490862, -103.31527747618576], {         
    nombre: "Algun lugar pizzeria ",
    direccion : "Av Cubilete 718, Chapalita Sur, 45040 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/C7mulCqu9v4/"
}))
cafeterias.push(L.marker([20.72120386841581, -103.43676180317662], {         
    nombre: "Macchina ",
    direccion : "P.º Valle Real 1276, Valle Real, 45019 Zapopan, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C3AslvGOZtt/"
}))
cortes_restauante.push(L.marker([20.67426160847444, -103.39141561867768], {         
    nombre: "Malala ",
    direccion : "Av. Ignacio L Vallarta 3089, Vallarta Poniente, 44110 Guadalajara, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C2xxVBguzXn/"
}))
brunchs.push(L.marker([20.677348305445328, -103.37844960318938], {         
    nombre: "Elefante Sabio ",
    direccion : "C. Francisco Rojas González 84, Arcos de Vallarta, 44650 Guadalajara, Jal.",
    ticket: 320,
    link:"https://www.instagram.com/p/C5Jj1AFO-ez/"
}))
cortes_restauante.push(L.marker([20.662060587239516, -103.42301933202585], {         
    nombre: "Surem Bacanora Steakhouse",
    direccion : "Av Guadalupe 4955-local 10, Jardines del Tepeyac, 45034 Zapopan, Jal.",
    ticket: 700,
    link:"https://www.instagram.com/p/C2YdLaUOMFO/"
}))
comida_Thailandesa.push(L.marker([20.68262713138718, -103.39627104550239], {         
    nombre: "Thaiger",
    direccion : "Av Aztecas 270, Monraz, 44670 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C2aj1G0uYNT/"
}))
taquerias.push(L.marker([20.662895858256345, -103.41767186084644], {         
    nombre: "Don Diablo",
    direccion : "Av Guadalupe 4543, Jardines de Guadalupe, 45030 Zapopan, Jal. ",
    ticket: 280,
    link:"https://www.instagram.com/p/C9D-LXJOvns/"
}))
bares.push(L.marker([20.67574386739936, -103.36285827433838], {         
    nombre: "Tyrano",
    direccion : "C. Pedro Moreno 1212, Col Americana, Americana, 44600 Guadalajara, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/C2gO_BbuA2y/"
}))
cortes_restauante.push(L.marker([20.67574386739936, -103.36285827433838], {         
    nombre: "Hacienda Canelos",
    direccion : "C. Pedro Moreno 1212, Col Americana, Americana, 44600 Guadalajara, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C2h4Ar9unle/"
}))
cortes_restauante.push(L.marker([20.67574386739936, -103.36285827433838], {         
    nombre: "Hacienda Canelos",
    direccion : "C. Pedro Moreno 1212, Col Americana, Americana, 44600 Guadalajara, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C2h4Ar9unle/"
}))
sushis.push(L.marker([20.666514686734015, -103.40017765865075], {         
    nombre: "Tamashi",
    direccion : "Av Guadalupe 770, Chapalita, 45040 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C9p9wnROHJl/"
}))
gusguerias.push(L.marker([20.716040298867792, -103.31814336310728], {         
    nombre: "Dalas Wings",
    direccion : "Batallón de San Patricio 80, Infonavit Estadio | Mercado Independencia local 97 entre Jorullo y Monte Atlas.",
    ticket: 140,
    link:"https://www.instagram.com/p/C2tEM6JOxWU/"
}))
antojitos_Mexicanos.push(L.marker([20.679311765767086, -103.38700983014463], {         
    nombre: "GuMorning",
    direccion : "Av. México 2789, Vallarta Nte., 44690 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C2uxEwBu-fy/"
}))
bares.push(L.marker([20.673075708379724, -103.37767778892707], {         
    nombre: "The Colony Mx",
    direccion : "C. Francisco Javier Gamboa 102, Col Americana, Americana, 44130 Guadalajara, Jal..",
    ticket: 400,
    link:"https://www.instagram.com/p/C2vsVsquatf/"
}))
antojitos_Mexicanos.push(L.marker([20.67958739731744, -103.36963898549871], {         
    nombre: "Tamales Bertha",
    direccion : "C. Pedro Antonio Buzeta 205",
    ticket: 60,
    link:"https://www.instagram.com/p/C2yMT53OcnP/"
}))
antojitos_Mexicanos.push(L.marker([20.677569043182586, -103.36909593211804], {         
    nombre: "Tamales del Country",
    direccion : "Av. Chapultepec Nte. 99",
    ticket: 70,
    link:"https://www.instagram.com/p/C2yMT53OcnP/"
}))
antojitos_Mexicanos.push(L.marker([20.68414774361273, -103.36718381862559], {         
    nombre: "Tamales Chela",
    direccion : "Calle Gabriel Ramos Millán",
    ticket: 70,
    link:"https://www.instagram.com/p/C2yMT53OcnP/"
}))
antojitos_Mexicanos.push(L.marker([20.68102438088906, -103.35584166095384], {         
    nombre: "Ricos Tamales desde 1953",
    direccion : "Calle Garibaldi 781",
    ticket: 60,
    link:"https://www.instagram.com/p/C2yMT53OcnP/"
}))
brunchs.push(L.marker([20.586615349831746, -103.44687854376072], {         
    nombre: "Pata de elefante",
    direccion : "Av. Adolfo López Mateos Sur 1710-Local PB1, Santa Isabel, El Palomar, 45645 Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C2zzzAaOeZe/"
}))
antojitos_Mexicanos.push(L.marker([20.688784128759092, -103.29390656095379], {         
    nombre: "Los güerazos de oblatose",
    direccion : "Av Joaquín Amaro 1267A",
    ticket: 120,
    link:"https://www.instagram.com/p/C20wkiIORVX/"
}))
cortes_restauante.push(L.marker([20.65431410241836, -103.40129166095433], {         
    nombre: "Hacienda Canelos",
    direccion : "Av. Adolfo López Mateos Sur 2198, Cd del Sol, 45050 Zapopan, Jal.",
    ticket: 400,
    link:"https://www.instagram.com/p/C2219Vvu3-d/"
}))
taquerias.push(L.marker([20.699781444635246, -103.32667051677403], {         
    nombre: "Ahumaditos",
    direccion : "Sierra Nevada 1806, Monumental, 44320 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C5HFpsOOlIx/"
}))
antojitos_Mexicanos.push(L.marker([20.724563180856148, -103.37994897444557], {         
    nombre: "Pollitos Kiki",
    direccion : "C. Epigmenio Preciado 579, Los Maestros, 45150 Guadalajara, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C25YCGPOGAt/"
}))
cortes_restauante.push(L.marker([20.689494570123706, -103.42662997444609], {         
    nombre: "Epifanía",
    direccion : "Av. Juan Palomar y Arias 646, Vallarta Universidad, 45110 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C28DbabOGhj/"
}))
cortes_restauante.push(L.marker([20.689494570123706, -103.42662997444609], {         
    nombre: "Epifanía",
    direccion : "Av. Juan Palomar y Arias 646, Vallarta Universidad, 45110 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C28DbabOGhj/"
}))
marisquerias.push(L.marker([20.741627495306897, -103.43191400182906], {         
    nombre: "El Venadito de Mazatlán",
    direccion : "Av Valle de Atemajac Ext. 2445-local 13-B, Jardín Real, 45136 Zapopan, Jal.",
    ticket: 290,
    link:"https://www.instagram.com/p/C3I4RAaO38C/"
}))
cafeterias.push(L.marker([20.67415150501856, -103.36889203188652], {         
    nombre: "Café Providencia",
    direccion : "Av. Chapultepec Sur 126, Col Americana, Lafayette, 44150 Guadalajara, Jal.",
    ticket: 270,
    link:"https://www.instagram.com/p/C8HsQz5u5dK/"
}))
taquerias.push(L.marker([20.663071555572085, -103.30467763638268], {         
    nombre: "Al Grill",
    direccion : "C. Gigantes 2421, San Andrés, 44730 Guadalajara, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/C3OnIMRuqgL/"
}))
cocina_Autor.push(L.marker([20.66243358089675, -103.40733593188673], {         
    nombre: "Gulia",
    direccion : "C. Gigantes 2421, San Andrés, 44730 Guadalajara, Jal.",
    ticket: 320,
    link:"https://www.instagram.com/p/C3QqIeROkrj/"
}))
marisquerias.push(L.marker([20.69810074451891, -103.33169353188595], {         
    nombre: "Caguamar",
    direccion : "Av. Cvln. División del Nte. 70, Independencia, 44379 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C3VsyoBuN0P/"
}))
marisquerias.push(L.marker([20.685409055262497, -103.37370943188618], {         
    nombre: "Caguamar",
    direccion : "Av. de las Américas 455-Local 15 y 16, Ladrón de Guevara, Ladron De Guevara, 44600 Guadalajara, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C3VsyoBuN0P/"
}))
hamburgeserias.push(L.marker([20.612622138937724, -103.31498017421474], {         
    nombre: "Cafetería El Mirador",
    direccion : "Constitución de 1857 3876, El Tapatío, 45580 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C3Y6zuBOYyw/"
}))
hotDogs.push(L.marker([20.72403248085011, -103.45440446072188], {         
    nombre: "Dogos Nainari",
    direccion : "Av. Aviación 4455, San Juan de Ocotán",
    ticket: 150,
    link:"https://www.instagram.com/p/C3bcaveOVmT/"
}))
hotDogs.push(L.marker([20.664820042343244, -103.43714567421364], {         
    nombre: "Dogos Nainari",
    direccion : "Av. Piotr Ilich Tchaikovski 578 Colonia, Arcos de Guadalupe",
    ticket: 150,
    link:"https://www.instagram.com/p/C3bcaveOVmT/"
}))
cortes_restauante.push(L.marker([20.628279721887722, -103.39476407421432], {         
    nombre: "Los Arre",
    direccion : "Av. Patria 3191, Jardines del Sauz, 44987 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C3ddQ6Yuf6k/"
}))
taquerias.push(L.marker([20.70303678086605, -103.29479667421278], {         
    nombre: "Tacos de Barbacoa Kaly",
    direccion : "Julián Carrillo 1791, Santa Cecilia, 44700 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C3f3l6fuFUF/"
}))
taquerias.push(L.marker([20.67206515703748, -103.27589573188654], {         
    nombre: "Tacos El Martín",
    direccion : "Av Presa de Osorio 3910, Lagos de Oriente, 44770 Guadalajara, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C3goj8oOr2m/"
}))
taquerias.push(L.marker([20.66508435796536, -103.4318522453771], {         
    nombre: "Tacos Los Lojan",
    direccion : "Av. Ecónomos 5342 E, Arcos de Guadalupe, 45037 Zapopan, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/C3lydH7ujEy/"
}))
antojitos_Mexicanos.push(L.marker([20.743525947519938, -103.39884614537539], {         
    nombre: "Los Muchachos de Zapopan",
    direccion : "Av Valdepeñas, C. Covadonga 2950, Lomas de Zapopan, 45130 Zapopan, Jal.",
    ticket: 250,
    link:"https://www.instagram.com/p/C3nWNnluduW/"
}))
sushis.push(L.marker([20.658748322582706, -103.44336317971486], {         
    nombre: "Black Pandas Sushi box",
    direccion : "Av Guadalupe 6493-A, Chapalita Inn, Exitmex, 45010 Zapopan, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C3s-v3IOgv9/"
}))
sushis.push(L.marker([20.658748322582706, -103.44336317971486], {         
    nombre: "Black Pandas Sushi box",
    direccion : "Av Guadalupe 6493-A, Chapalita Inn, Exitmex, 45010 Zapopan, Jal.",
    ticket: 200,
    link:"https://www.instagram.com/p/C3s-v3IOgv9/"
}))
antojitos_Mexicanos.push(L.marker([20.679351593661575, -103.38698544537681], {         
    nombre: "Gunight",
    direccion : "Av. México 2789, Vallarta Nte., 44600 Guadalajara, Jal.",
    ticket: 290,
    link:"https://www.instagram.com/p/C3wEB88uEOj/"
}))
antojitos_Mexicanos.push(L.marker([20.679351593661575, -103.38698544537681], {         
    nombre: "Gunight",
    direccion : "Av. México 2789, Vallarta Nte., 44600 Guadalajara, Jal.",
    ticket: 290,
    link:"https://www.instagram.com/p/C3wEB88uEOj/"
}))
antojitos_Mexicanos.push(L.marker([20.709507994455336, -103.40976168978933], {         
    nombre: "Menudería La Estancia",
    direccion : "Av. Patria 1891-Local 104, Lobby 33, 45410 Zapopan, Jal..",
    ticket: 350,
    link:"https://www.instagram.com/p/C3x7S9QOJrC/"
}))
comida_Francesa.push(L.marker([20.664028142297795, -103.35854370328236], {         
    nombre: "Le Bon Plan",
    direccion : "Av. Alemania 1410, Moderna, 44170 Guadalajara, Jal.",
    ticket: 380,
    link:"https://www.instagram.com/p/C3yqMCRO3Fr/"
}))
antojitos_Mexicanos.push(L.marker([20.674450456726532, -103.35072550332002], {         
    nombre: "Los Originales de Santa Tere",
    direccion : "C. Donato Guerra 140, Zona Centro, 44100 Guadalajara, Jal.",
    ticket: 130,
    link:"https://www.instagram.com/p/C35WqbdORbV/"
}))
hamburgeserias.push(L.marker([20.711551737802157, -103.41140035102127], {         
    nombre: "Bruto",
    direccion : "Esquina, Blvrd Puerta de Hierro 4965, Puerta de Hierro, 45116 Zapopan, Jal.",
    ticket: 320,
    link:"https://www.instagram.com/p/C36ZYy7u3qj/"
}))
cafeterias.push(L.marker([20.67284818089528, -103.40999464564823], {         
    nombre: "Gong Cha Chapalita",
    direccion : "PLAZA LOS CANDADOS, Av. Niño Obrero 476-local 10, Camino Real, 45040 Zapopan, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C38cH30uHvc/"
}))
marisquerias.push(L.marker([20.638329460013363, -103.31591187438566], {         
    nombre: "Ronyto",
    direccion : "C. Juárez 283 A, Lomas de Tlaquepaque, 45559 Guadalajara, Jal.",
    ticket: 300,
    link:"https://www.instagram.com/p/C4BfXu5Oj9b/"
}))
gusguerias.push(L.marker([20.64142806078975, -103.39108725588879], {         
    nombre: "Nao Papas & Snacks",
    direccion : "Av. Lapizlázuli 2545-Int. 8, Bosques de La Victoria, 44580 Guadalajara, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C4MaJ89ugbz/"
}))
pizzerias.push(L.marker([20.675784459524856, -103.37031033296168], {         
    nombre: "La Nona Lola",
    direccion : "C. José María Morelos 1739-int. #7, Col Americana, Lafayette, 44600 Guadalajara, Jal.",
    ticket: 230,
    link:"https://www.instagram.com/p/C7An5E8OfdD/"
}))
taquerias.push(L.marker([20.691711463503374, -103.32376055628175], {         
    nombre: "Tripitas Los Panchos",
    direccion : "Av. Belisario Domínguez 1430, Lomas Independencia, 44370 Guadalajara, Jal.",
    ticket: 180,
    link:"https://www.instagram.com/p/C4RjWFyumWo/"
}))
cortes_restauante.push(L.marker([20.60854281151331, -103.26969493148599], {         
    nombre: "Herradero de Quitupan",
    direccion : "Libre a Zapotlanejo N°3696, Los Puestos, 45638 San Pedro Tlaquepaque, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C4TkJ4KuqEY/"
}))
cocina_Oriental.push(L.marker([20.675827417093817, -103.37889116131926], {         
    nombre: "SSAM",
    direccion : "Av. José María Morelos 2122, Arcos Vallarta, 44600 Guadalajara, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/C4Wr_AXuu_4/"
}))
taquerias.push(L.marker([20.682243675227763, -103.41959864113969], {         
    nombre: "Don Cayetano",
    direccion : "Av Inglaterra, Maurice Baring esq, Jardines de la Patria, 45027 Zapopan, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C4YPRarO0D-/"
}))
bares.push(L.marker([20.672992915142792, -103.37605215855069], {         
    nombre: "Cantina El Mandatario",
    direccion : "C. Juan Ruiz de Alarcón 123, Col Americana, Lafayette, 44150 Guadalajara, Jal.",
    ticket: 380,
    link:"https://www.instagram.com/p/C906rLLODSq/"
}))
cafeterias.push(L.marker([20.67578082854086, -103.36145024020757], {         
    nombre: "KONA",
    direccion : "C. Pedro Moreno 1104, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 150,
    link:"https://www.instagram.com/p/C4a1LWYOS6G/"
}))
pizzerias.push(L.marker([20.660461433974746, -103.40548929466209], {         
    nombre: "Algún Lugar Pizzería",
    direccion : "Av Cubilete 718, Chapalita Sur, 45040 Zapopan, Jal.",
    ticket: 270,
    link:"https://www.instagram.com/p/C4d5Ty0Oyji/"
}))
chocolateria.push(L.marker([20.673117158483283, -103.36226820558353], {         
    nombre: "Mis Negras Intenciones",
    direccion : "C. Emeterio Robles Gil 235, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 350,
    link:"https://www.instagram.com/p/C4geNnru1XA/"
}))
taquerias.push(L.marker([20.642731838994877, -103.41687557875595], {         
    nombre: "Tacos Las Ardillas",
    direccion : "C. Volcán Jorullo 7, Colli Urbano, 45070 Zapopan, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C4ijsVHu106/"
}))
brunchs.push(L.marker([20.600736940942372, -103.44837817425781], {         
    nombre: "Otto Taco",
    direccion : "Blvd. Bugambilias #2299, Bugambilias, 45237 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C4qjcqXO_22/"
}))
taquerias.push(L.marker([20.65741191710361, -103.37020256814871], {         
    nombre: "Tacos Carlos",
    direccion : "C. Fresno 1968, Del Fresno, 44900 Guadalajara, Jal.",
    ticket: 100,
    link:"https://www.instagram.com/p/C4rUkBbOGY8/"
}))
panaderias.push(L.marker([20.671177260714312, -103.34610981385066], {         
    nombre: "Panadería Delicious",
    direccion : "Calle Pedro loza 230 Corona 393, Alcalde 295, Centro, 44100 Guadalajara, Jal.",
    ticket: 50,
    link:"https://www.instagram.com/p/C4s5Ko9OmMc/"
}))
cortes_restauante.push(L.marker([20.72282692458445, -103.39426473288434], {         
    nombre: "La Resortera",
    direccion : "C. 16 de Septiembre 321, Col. Centro, 45100 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C4v6hR0uy0h/"
}))
tortas_Ahogadas.push(L.marker([20.683523293789438, -103.34821104896668], {         
    nombre: "Las Grandes Tortas Ahogadas",
    direccion : "C. Manuel Acuña 427, Zona Centro, 44200 Guadalajara, Jal.",
    ticket: 120,
    link:"https://www.instagram.com/p/C48VCg0Ogbv/"
}))
sushis.push(L.marker([20.7303805028008, -103.44135779796754], {         
    nombre: "Sushi Itamae",
    direccion : "Av. Sta. Margarita 4824-4, Jardín Real, 45136 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C4_YiS0Oq2A/"
}))
sushis.push(L.marker([20.7303805028008, -103.44135779796754], {         
    nombre: "Sushi Itamae",
    direccion : "Av. Sta. Margarita 4824-4, Jardín Real, 45136 Zapopan, Jal.",
    ticket: 280,
    link:"https://www.instagram.com/p/C5OVUn6Oncs/"
}))
cafeterias.push(L.marker([20.67389456209279, -103.34794039202629], {         
    nombre: "De carmen café",
    direccion : "C. Colón 180, Zona Centro, 44100 Guadalajara, Jal.",
    ticket: 90,
    link:"https://www.instagram.com/p/C5OVUn6Oncs/"
}))
antojitos_Mexicanos.push(L.marker([20.637855655966256, -103.30841588446704], { 
    nombre: "Cenaduría La unica",   
    direccion: "Calle 5 de Mayo 71, Centro, 45500 San Pedro Tlaquepaque, Jal.",
    ticket: 180.0, 
    link: "https://www.instagram.com/p/C5PWopYO0au/"
}))
brunchs.push(L.marker([20.69680040233728, -103.38873900175798], { 
    nombre: "Nerium",
    direccion: "Buenos Aires 3086-Sección 1, Providencia, 44630 Guadalajara, Jal.",
    ticket: 320.0,
    link: "https://www.instagram.com/p/C97pxjtO80z/"
}))
taquerias.push(L.marker([20.749949613652348, -103.39904331920236], {
    nombre: "Tacos de asada Los Carnales",
    direccion: "Av Valdepeñas 221-224, Lomas de Zapopan, 45130 Zapopan, Jal.",
    ticket: 160.0,
    link: "https://www.instagram.com/p/C5R8Ir7uDxI/"
}))
cafeterias.push(L.marker([20.569428799274345, -103.45763806175296], {
    nombre: "Gong cha ",
    direccion: "Urban Center, Av. Adolfo López Mateos Sur 7000-Local 27, La Rioja. Justo aún lado del Fresko.",
    ticket: 90.0,
    link: "https://www.instagram.com/p/C5UbWnJO6Ha/"
}))
antojitos_Mexicanos.push(L.marker([20.654750116218974, -103.44274060332725], {
    nombre: "El rincon campestre",
    direccion: "C. Misión de Santo Tomás Zapopan 1069B, Plaza Guadalupe, Residencial Guadalupe, 45036 Zapopan, Jal.",
    ticket: 180.0,
    link: "https://www.instagram.com/p/C5WiaunuWnF/"
}))
polleria.push(L.marker([20.674179998195978, -103.43089608983283], {
    nombre: "Pollos Bravo",
    direccion: "Av. Sebastian Bach 5529-Local 1, La Estancia, 45030 Zapopan, Jal.",
    ticket: 220.0,
    link: "https://www.instagram.com/p/C5ZHP9wuHFi/"
}))
taquerias.push(L.marker([19.389396360989235, -99.15268548403546], {
    nombre: "Tacos el vilisito",
    direccion: "Petén 248 y, Av. Universidad, Narvarte Poniente, 03020 Ciudad de México, CDMX",
    ticket: 250.0,
    link: "https://www.instagram.com/p/C5ZsV-FOn62/"
}))
campestre.push(L.marker([20.62336596351173, -103.21985603233682], {
    nombre: "Fogón de Cirilo",
    direccion: "Cam. Al Vado 2507, 45400 Tonalá, Jal.",
    ticket: 260.0,
    link: "https://www.instagram.com/p/C5bd1CTOG6j/"
}))

brunchs.push(L.marker([19.4101666199694, -99.17133616119168], {
    nombre: "Mendl",
    direccion: "Citlaltépetl 9, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    ticket: 320.0,
    link: "https://www.instagram.com/p/C5jPLX5u8CJ/"
}))
gusguerias.push(L.marker([20.69485454431297, -103.44285017466562], {
    nombre: "Mr. Pancake",
    direccion: "Av Naciones Unidas 6885, Vistas del Tule, Sin Nombre, 45136 Zapopan, Jal.",
    ticket: 120.0,
    link: "https://www.instagram.com/p/C5j8FOhu1Bf/"
}))
gusguerias.push(L.marker([19.40646422043305, -99.1245167611917], {
    nombre: "Raspados La Señora del Sombrero",
    direccion: "Guillermo Prieto 45, Jamaica, Venustiano Carranza, 15800 Ciudad de México, CDMX",
    ticket: 40.0,
    link: "https://www.instagram.com/p/C5l-9WDOSpx/"
}))
cocina_Internacional.push(L.marker([20.729253349429587, -103.43318920350008], {
    nombre: "Enraclettados",
    direccion: "Av. santa margarita 3849 -1B Plaza Toscana, 45019 Zapopan, Jal.",
    ticket: 220.0,
    link: "https://www.instagram.com/p/C5rJtMcuaSi/"
}))
cafeterias.push(L.marker([20.673869404987894, -103.34791514583101], {
    nombre: "De Carmen",
    direccion: "C. Colón 180, Zona Centro, 44100 Guadalajara, Jal.",
    ticket: 90.0,
    link: "https://www.instagram.com/p/C5tjG3YOWTo/"
}))
antojitos_Mexicanos.push(L.marker([19.410064111019068, -99.1241910559393], {
    nombre: "El Huarache Azteca",
    direccion: "Torno 154 esq, Juan A. Mateos, Artes Gráficas, Venustiano Carranza, 15830 CDMX",
    ticket: 200.0,
    link: "https://www.instagram.com/p/C5uPidEOCxI/"
}))
panaderias.push(L.marker([19.41991651874787, -99.1605438323565], {
    nombre: "Panadería Rosetta",
    direccion: "Colima 179, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX",
    ticket: 120.0,
    link: "https://www.instagram.com/p/C5v_fzKuRo0/"
}))
taquerias.push(L.marker([19.338518734929956, -99.21855076119276], {
    nombre: "Los Milanesos",
    direccion: "C. Glaciar 121, Olivar de los Padres, Álvaro Obregón, 01780 Ciudad de México, CDMX",
    ticket: 80.0,
    link: "https://www.instagram.com/p/C509Q_CON2T/"
}))
cortes_restauante.push(L.marker([20.662251323074177, -103.42305151884125], {
    nombre: "La Rafaela",
    direccion: "Av Guadalupe 4955-local 10, Jardines del Tepeyac, 45034 Zapopan, Jal.",
    ticket: 450.0,
    link: "https://www.instagram.com/p/C51dOYDuGnJ/"
}))
brunchs.push(L.marker([20.62851626282857, -103.42015536117181], {
    nombre: "Colomos Restaurante",
    direccion: "Av. Nicolás Copérnico 3675, Arboledas, 45070 Guadalajara, Jal.",
    ticket: 280.0,
    link: "https://www.instagram.com/p/C53h6nNOv4_/"
}))
antojitos_Mexicanos.push(L.marker([20.745033105569505, -103.37400991147206], {
    nombre: "Los Muchachos de Zapopan",
    direccion: "Av de las torres, Jardines de las Begonias 865, Villa de los Belenes, 45130 Zapopan, Jal.",
    ticket: 230.0,
    link: "https://www.instagram.com/p/C58-UzkODzw/"
}))
bares.push(L.marker([19.424243581859983, -99.16498896119145], {
    nombre: "Habdshake",
    direccion: "C. Amberes 65, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX",
    ticket: 600.0,
    link: "https://www.instagram.com/p/C6C2YXQODSV/"
}))
tortas_Ahogadas.push(L.marker([20.744512247565165, -103.42634404582981], {
    nombre: "Tortas Ahogadas Mayorga",
    direccion: "Av. del Valle 1689, Jardines del Valle, 45138 Zapopan, Jal.",
    ticket: 100.0,
    link: "https://www.instagram.com/p/C6EaV1IO1mY/"
}))
taquerias.push(L.marker([19.418006144744922, -99.16319420352148], {
    nombre: "Taquería Orinoco",
    direccion: "Av. Álvaro Obregón 179, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX",
    ticket: 200.0,
    link: "https://www.instagram.com/p/C6FcBLqu6Pk/"
}))
bbqY_ahumador.push(L.marker([20.64443570112111, -103.30527900350152], {
    nombre: "Al Humo Ribs",
    direccion: "Av Río Nilo 2699, Jardines de la Paz, 44860 Guadalajara, Jal.",
    ticket: 280.0,
    link: "https://www.instagram.com/p/C6KC9ZxuENe/"
}))
cortes_restauante.push(L.marker([20.64224568091851, -103.31382841699649], {
    nombre: "El Gorderón",
    direccion: "Calle Contreras Medellín 167, San Juan, 45500 San Pedro Tlaquepaque, Jal.",
    ticket: 290.0,
    link: "https://www.instagram.com/p/C6MnEPKuHvv/"
}))
brunchs.push(L.marker([20.67159526658628, -103.37459100350108], {
    nombre: "Baham café",
    direccion: "Miguel Cervantes Saavedra, C. Miguel Lerdo de Tejada 197 Esquina, AMERICANA, Lafayette, 44150 Guadalajara, Jal.",
    ticket: 290.0,
    link: "https://www.instagram.com/p/C6OuDFEOgf-/"
}))
antojitos_Mexicanos.push(L.marker([19.40834038187237, -99.17654846119166], {
    nombre: "La Esquina del Chilaquil",
    direccion: "Alfonso Reyes 139, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    ticket: 60.0,
    link: "https://www.instagram.com/p/C6RTHrwONr7/"
}))
marisquerias.push(L.marker([20.689143206996548, -103.37908656117075], {
    nombre: "La Baja",
    direccion: "C. Jesús García 2455, Providencia, 44630 Guadalajara, Jal.",
    ticket: 320.0,
    link: "https://www.instagram.com/p/C6RyyxxuahO/"
}))
gusguerias.push(L.marker([20.630864140133237, -103.37869290350179], {
    nombre: "El Rincón de la Gusguera JT",
    direccion: "C. Longinos Cadena 2373, Polanco, 44960 Guadalajara, Jal.",
    ticket: 100.0,
    link: "https://www.instagram.com/p/C6aCvzfO21-/"
}))
taquerias.push(L.marker([20.643199541048972, -103.37997173233656], {
    nombre: "Super Takko",
    direccion: "ESQ. islas cozumel, C. Francisco Vázquez Coronado, Jardines de La Cruz, 44950 Guadalajara, Jal.",
    ticket: 120.0,
    link: "https://www.instagram.com/p/C9KsrIxO2Je/"
}))
hamburgeserias.push(L.marker([20.674179218828087, -103.37125140350106], {
    nombre: "Hyper",
    direccion: "Av. Ignacio L Vallarta 1589, Col Americana, Lafayette, 44150 Guadalajara, Jal.",
    ticket: 300.0,
    link: "https://www.instagram.com/p/C6ckWhzuliK/"
}))
marisquerias.push(L.marker([20.64021755389135, -103.30984896748684], {
    nombre: "Nola Grill",
    direccion: "C. Morelos 6, Centro, 45500 San Pedro Tlaquepaque, Jal.",
    ticket: 380,
    link: "https://www.instagram.com/p/C-Dz5PKupiO/"
}))
brunchs.push(L.marker([20.66291278097595, -103.35847803224493], {
    nombre: "La trinchera",
    direccion: "C. Francia 1400, Moderna, 44190 Guadalajara, Jal.",
    ticket: 300,
    link: "hhttps://www.instagram.com/p/C9-SCCTuwd7/"
}))
bares.push(L.marker([19.434724564401492, -99.13954497459963], {
    nombre: "Bar La Ópera",
    direccion: "Av. 5 de Mayo #10, Centro Histórico de la Cdad. de México, de la, Cuauhtémoc, 06000 Ciudad de México, CDMX",
    ticket: 350,
    link: "https://www.instagram.com/p/C6hwVhAOHUj/"
}))
taquerias.push(L.marker([20.65045951800195, -103.3805245610805], {
    nombre: "Birrería Apatzingán",
    direccion: "Av. del Mercado 1527, Las Torres, 44950 Guadalajara, Jal.",
    ticket: 140,
    link: "https://www.instagram.com/p/C6jXACzO57N/"
}))
taquerias.push(L.marker([20.665941342495998, -103.43160304573885], {
    nombre: "El Santo Taco",
    direccion: "Av Rafael Sanzio 527, Arcos de Guadalupe, 45036 Zapopan, Jal.",
    ticket: 150,
    link: "https://www.instagram.com/p/C6kXR2kOSyh/"
}))
marisquerias.push(L.marker([19.415220100670336, -99.16214533227067], {
    nombre: "Mi Compa Chava",
    direccion: "Zacatecas 172, Roma Nte., Cuauhtémoc, 06700 Ciudad de Mexico",
    ticket: 650,
    link: "https://www.instagram.com/p/C6m0LzROiTa/"
}))
marisquerias.push(L.marker([20.677110543225783, -103.37579273224459], {
    nombre: "Pier23",
    direccion: "Av. Miguel Hidalgo y Costilla 1798, Ladrón de Guevara, Ladron De Guevara, 44650 Guadalajara, Jal.",
    ticket: 320,
    link: "https://www.instagram.com/p/C6o-NjEOl2q/"
}))
brunchs.push(L.marker([19.41647990059104, -99.16212373227071], {
    nombre: "Bou",
    direccion: "Tonalá 110, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX",
    ticket: 350,
    link: "https://www.instagram.com/p/C6rCB7zxNoc/"
}))
marisquerias.push(L.marker([19.421816808642305, -99.20739007459989], {
    nombre: "El Jarocho de las Lomas",
    direccion: "Iturrigaray 105, Lomas - Virreyes, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México, CDMX",
    ticket: 220,
    link: "https://www.instagram.com/p/C6rhAy7ucLP/"
}))
antojitos_Mexicanos.push(L.marker([20.65658140278924, -103.38144138991564], {
    nombre: "Carnes Asadas Ramón",
    direccion: "C. 4 430-442, Comercial Abastos, 44530 Guadalajara, Jal.",
    ticket: 120,
    link: "https://www.instagram.com/p/C6tnPoPuLIq/"
}))
bbqY_ahumador.push(L.marker([20.638248576984687, -103.43170747076286], {
    nombre: "Chancho Prieto",
    direccion: "Miguel Lerdo de Tejada 1965 y Felipe Zetter 2960.",
    ticket: 290,
    link: "https://www.instagram.com/p/C6wtpoqOKvy/"
}))
brunchs.push(L.marker([20.674419584329485, -103.3649779340918], {
    nombre: "Casa Dely",
    direccion: "Calle Emerson 123, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 290,
    link: "https://www.instagram.com/p/C6ywlnsO0N4/"
}))
cortes_restauante.push(L.marker([19.430147888762757, -99.19755786110571], {
    nombre: "L'entrecote",
    direccion: "Av. Emilio Castelar 121-local J, Polanco, Polanco III Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX",
    ticket: 600,
    link: "https://www.instagram.com/p/C62WQ9uOea9/"
}))
brunchs.push(L.marker([20.66792619601573, -103.36687400340944], {
    nombre: "Piggy Back",
    direccion: "C. Progreso 537, Col Americana, Americana, 44150 Guadalajara, Jal.",
    ticket: 250,
    link: "https://www.instagram.com/p/C64J_LaOTHr/"
}))
gusguerias.push(L.marker([20.66792619601573, -103.36687400340944], {
    nombre: "Churrería El Moro",
    direccion: "C. Progreso 537, Col Americana, Americana, 44150 Guadalajara, Jal.",
    ticket: 80,
    link: "https://www.instagram.com/p/C647d0YuxIM/"
}))
marisquerias.push(L.marker([20.74103528091695, -103.43256960340781], {
    nombre: "La Reina de Mazatlán",
    direccion: "Av Valle de Atemajac 2445, Jardines del Valle, 45136 Zapopan, Jal.",
    ticket: 320,
    link: "https://www.instagram.com/p/C6697gtOiNt/"
}))
cortes_restauante.push(L.marker([20.679778268788, -103.3918390745739], {
    nombre: "Borca Asador",
    direccion: "Av. México 3040, Juan Manuel Vallarta, 44680 Guadalajara, Jal.",
    ticket: 490,
    link: "https://www.instagram.com/p/C7Csml1OLEH/"
}))
marisquerias.push(L.marker([20.675341170258413, -103.40984526107995], {
    nombre: "Mariscos al 120",
    direccion: "Camino Real, 45040 Zapopan, Jal.",
    ticket: 250,
    link: "https://www.instagram.com/p/C7FPa6TOnCK/"
}))
campestre.push(L.marker([20.608596507807892, -103.26967467457543], {
    nombre: "El herradero de quitupan",
    direccion: "Libre a Zapotlanejo N°3696, Los Puestos, 45638 San Pedro Tlaquepaque, Jal..",
    ticket: 320,
    link: "https://www.instagram.com/p/C7M_v7Iu2Ol/"
}))
nieves.push(L.marker([20.686064755254815, -103.36617427642089], {
    nombre: "Caravell",
    direccion: "C. José Clemente Orozco 569, Santa Teresita, 44600 Guadalajara, Jal.",
    ticket: 90,
    link: "https://www.instagram.com/p/C7PhctKOvtT/"
}))
pizzerias.push(L.marker([20.672895185136877, -103.41000797457407], {
    nombre: "Bocazza Pizza & chela",
    direccion: "Av. Niño Obrero 476, Camino Real, 45040 Zapopan, Jal.",
    ticket: 280,
    link: "https://www.instagram.com/p/C7SKk5CuAcn/"
}))
marisquerias.push(L.marker([20.644371401185797, -103.28442064573927], {
    nombre: "Mariscos Kamar",
    direccion: "Av Río Nilo 3866, Jardines de Los Historiadores, 44825 Guadalajara, Jal.",
    ticket: 320,
    link: "https://www.instagram.com/p/C7UuN3jObpO/"
}))
sushis.push(L.marker([20.66339936504065, -103.40108141875078], {
    nombre: "Hakken",
    direccion: "Av. Tepeyac 655, Chapalita, 45030 Guadalajara, Jal.",
    ticket: 300,
    link: "https://www.instagram.com/p/C7XSdYlOG18/"
}))
marisquerias.push(L.marker([20.673494932888264, -103.43062596107997], {
    nombre: "El chacal",
    direccion: "Rafael Sanzio 226, Zapopan",
    ticket: 280,
    link: "https://www.instagram.com/p/C7e9RxEumqU/"
}))
cafeterias.push(L.marker([20.706887202103413, -103.32434537457335], {
    nombre: "Upward coffe",
    direccion: "Calzada Independencia Norte 2238, local 5. Plaza Tula",
    ticket: 120,
    link: "https://www.instagram.com/p/C7hHCAUxxQC/"
}))
taquerias.push(L.marker([20.646769478465494, -103.43256338806863], {
    nombre: "Asadas El Concho",
    direccion: "C. Volcan de Baru 1887b, Colli Urbano, 45070 Zapopan, Jal.",
    ticket: 140,
    link: "https://www.instagram.com/p/C7iI4ZyOTCD/"
}))
taquerias.push(L.marker([20.738186115707368, -103.44894594573732], {
    nombre: "El tejaban en aviacion",
    direccion: "Av. Aviación 4368 - 2, El Real 1, 45019 Zapopan, Jal.",
    ticket: 120,
    link: "https://www.instagram.com/p/C7ju4sZO_-u/"
}))
brunchs.push(L.marker([20.674978167327854, -103.36495886107997], {
    nombre: "Cafetria Victoria",
    direccion: "Calle Emerson 77, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket: 200,
    link: "https://www.instagram.com/p/C7rq-4kuKHb/"
}))
nieves.push(L.marker([20.626998463102872, -103.24581887457495], {
    nombre: "Amoripax",
    direccion: "Av. Tonaltecas 71-D, Tonalá Centro, 45400 Tonalá, Jal.",
    ticket: 90,
    link: "https://www.instagram.com/p/C7ubXgXOnhq/"
}))
taquerias.push(L.marker([ 19.415095569234502, -99.1324028322119 ], {
    nombre: " El Gran Abanico ",
    direccion: "  Gutiérrez Nájera s/n, Tránsito, Cuauhtémoc, 06820 Ciudad de México, CDMX ",
    ticket:  120.0 ,
    link: " https://www.instagram.com/p/C7zLe8ruXF4/ "
 }))
antojitos_Mexicanos.push(L.marker([ 20.648845420435197, -103.30010938800869 ], {
    nombre: "  Flautas La Furia ",
    direccion: "  C. Juan Suarez de Peralta 2891, Jardines de la Paz, 44860 Guadalajara, Jal. ",
    ticket:  120.0 ,
    link: " https://www.instagram.com/p/C70JbU_urgm/ "
 }))
comida_Italiana.push(L.marker([ 20.745780247139162, -103.41303006286874 ], {
    nombre: " Mia casa ",
    direccion: " Av. Juan Gil Preciado 1600, La Cima, 45134 Zapopan, Jal. ",
    ticket:  280.0 ,
    link: " https://www.instagram.com/p/C72NOOeO_kC/ "
 }))
gusguerias.push(L.marker([ 20.65987935548872, -103.44608045996907 ], {
    nombre: " Tres waffles ",
    direccion: " Inn, Av Guadalupe 6818-Local 19, Residencial Plaza Guadalupe, Plaza Guadalupe, 45030 ",
    ticket:  130.0 ,
    link: " https://www.instagram.com/p/C75So6DOcU9/ "
 }))
nieves.push(L.marker([ 20.666147616263352, -103.40576806473496 ], {
    nombre: " Nieves naturales Gomu ",
    direccion: " Jardín de San Ignacio, 45040 Zapopan, Jal. ",
    ticket:  90.0 ,
    link: " https://www.instagram.com/p/C77Xq-xO6Lg/ "
 }))
brunchs.push(L.marker([ 20.73734831703537, -103.43048707451352 ], {
    nombre: " Los Pancakes de Gdl ",
    direccion: "  Av. Jesús 3870, Real del Carmen, 45138 Zapopan, Jal. ",
    ticket:  250.0 ,
    link: " https://www.instagram.com/p/C79tATzOsFS/ "
 }))
taquerias.push(L.marker([ 20.675142018677555, -103.33980137636365 ], {
    nombre: " Los de Ollita ",
    direccion: " Av. Francisco Javier Mina 1179-Local 624, San Juan de Dios, 44360 Guadalajara, Jal. ",
    ticket:  140.0 ,
    link: " https://www.instagram.com/p/C8ALsQpuOeq/ "
 }))

comida_Francesa.push(L.marker([ 20.67744780537223, -103.37636666102141 ], {
    nombre: " Pierrot ",
    direccion: "  C. Justo Sierra 2355, Ladrón de Guevara, Ladron De Guevara, 44600 Guadalajara, Jal. ",
    ticket:  600.0 ,
    link: " https://www.instagram.com/p/C8GK4HsuiIx/ "
 }))

antojitos_Mexicanos.push(L.marker([ 20.67513390506793, -103.3393240880081 ], {
    nombre: " Tripitas Esperanza e Hijas ",
    direccion: " Av. Francisco Javier Mina 1179-Local 567, San Juan de Dios, 44360 Guadalajara, Jal. ",
    ticket:  130.0 ,
    link: " https://www.instagram.com/p/C8IwBELuwf8/ "
 }))
brunchs.push(L.marker([ 20.669979733661147, -103.37130350335046 ], {
    nombre: " alba cocina diversa ",
    direccion: " Calle Efraín González Luna 2142, Col Americana, Obrera, 44140 Guadalajara, Jal. ",
    ticket:  290.0 ,
    link: " https://www.instagram.com/p/C8KUvGGunuJ/ "
 }))
bares.push(L.marker([ 20.673853918763786, -103.36233850519919 ], {
    nombre: " El Desestrés  ",
    direccion: "  C. Manuel López Cotilla 1145, Col Americana, Americana, 44160 Guadalajara, Jal. ",
    ticket:  300.0 ,
    link: " https://www.instagram.com/fatphilia.gdl/ "
 }))
chocolateria.push(L.marker([ 20.673114770836737, -103.37145026102145 ], {
    nombre: " Mis Negras intenciones ",
    direccion: " C. Gral. San Martín 175, Col Americana, Lafayette, 44140 ",
    ticket:  300.0 ,
    link: " https://www.instagram.com/p/C8PqZQ4ObFT/ "
 }))
cocina_Oriental.push(L.marker([ 20.67256133847225, -103.36138713218592 ], {
    nombre: " Kit Cocina Asiática ",
    direccion: " Calle Prisciliano Sánchez 1008A, Col Americana, Americana, 44160 Guadalajara, Jal. ",
    ticket:  240.0 ,
    link: " https://www.instagram.com/p/C8SZ4wwux3B/ "
 }))
panaderias.push(L.marker([ 20.727815349535195, -103.41458240334924 ], {
    nombre: " Horno de José ",
    direccion: " Sta. Esther 241, Santa Margarita1a Secc., 45140 Zapopan, Jal. ",
    ticket:  120.0 ,
    link: " https://www.instagram.com/p/C8UmpRuu8aa/ "
 }))
hamburgeseriasYhotdogs.push(L.marker([ 20.637198144249364, -103.42833251684445 ], {
    nombre: " Kchimba  ",
    direccion: " Av. Enrique Ladrón de Guevara 3146, Paseos del Sol, 45079 Zapopan, Jal. ",
    ticket:  180.0 ,
    link: " https://www.instagram.com/p/C8VnoAGu4xd/ "
 }))
taquerias.push(L.marker([ 20.640378741076574, -103.39062837175942 ], {
    nombre: " Carnitas la Mezca ",
    direccion: " Av Cruz del Sur, Jardines de La Cruz, 44950 Guadalajara, Jal. ",
    ticket:  180.0 ,
    link: " https://www.instagram.com/p/C8XLAD1uB7Q/ "
 }))
taquerias.push(L.marker([ 20.62940232173335, -103.42253251684477 ], {
    nombre: " Clásicos pa taquear ",
    direccion: " Av. Nicolás Copérnico 3959, Arboledas, 45070 Zapopan, Jal. ",
    ticket:  240.0 ,
    link: " https://www.instagram.com/p/C8YMJdFum04/ "
 }))
brunchs.push(L.marker([ 20.695936653781764, -103.38812407451437 ], {
    nombre: " Pata de Elefante ",
    direccion: " Milán 3050, Providencia 4a. Sección, 44630 Guadalajara, Jal. ",
    ticket:  290.0 ,
    link: " https://www.instagram.com/p/C8e59G8uRtH/ "
 }))
marisquerias.push(L.marker([ 20.756045633215887, -105.33922948190859 ], {
    nombre: "  Mariscos Villarreal ",
    direccion: "  Dr. Abraham González 10, 63732 Bucerías, Nay. ",
    ticket:  280.0 ,
    link: " https://www.instagram.com/p/C8hvaBkuLUG/ "
 }))
taquerias.push(L.marker([ 20.694503571028104, -103.37550985917217 ], {
    nombre: " Los parados de providencia ",
    direccion: "  Av Adolfo López Mateos Nte 95, Italia Providencia, Guadalajara, Jal. (segundo piso, food court en Plaza Midtown) ",
    ticket:  180.0 ,
    link: " https://www.instagram.com/p/C8lEhQjuiC0/ "
 }))
cocina_Autor.push(L.marker([ 20.67755605622754, -103.38191677451475 ], {
    nombre: " Casa maro ",
    direccion: "  C. Justo Sierra 2573, Arcos Vallarta, 44130 Guadalajara, Jal. ",
    ticket:  320.0 ,
    link: " https://www.instagram.com/p/C8moAHzucUW/ "
 }))
antojitos_Mexicanos.push(L.marker([ 20.62346637628124, -103.21985603218697 ], {
    nombre: " Fógon de cirilo ",
    direccion: "  Cam. Al Vado 2507, 45416 Tonalá, Jal. ",
    ticket:  200.0 ,
    link: " https://www.instagram.com/p/C8ryRnZOzyT/ "
 }))
comida_Italiana.push(L.marker([ 20.680639880803216, -103.3945851033502 ], {
    nombre: " El Italiano ",
    direccion: " Diag. Golfo de Cortés 4134, Monraz, 44670 Guadalajara, Jal. ",
    ticket:  700.0 ,
    link: " https://www.instagram.com/p/C8u1QD9ueKj/ "
 }))
cocina_Autor.push(L.marker([ 19.42504033681614, -99.20330666104724 ], {
    nombre: " Carmela y Sal ",
    direccion: " Torre Virreyes, Pedregal 24, Lomas - Virreyes, Miguel Hidalgo, 11040 Ciudad de México, CDMX ",
    ticket:  800.0 ,
    link: " https://www.instagram.com/p/C83FKsLOebT/ "
 }))
hamburgeseriasYhotdogs.push(L.marker([ 20.66239508081716, -103.40046284567946 ], {
    nombre: " El canibal GDL ",
    direccion: "  Av de Las Rosas 432, 45046 Guadalajara y en Av. de la Paz 1800, Col Americana, 44160 Guadalajara ",
    ticket:  320.0 ,
    link: " https://www.instagram.com/p/C85IxeWObKQ/ "
 }))
pizzerias.push(L.marker([ 20.660342725432226, -103.40549781684392 ], {
    nombre: " Algún Lugar Pizzería ",
    direccion: " Av Cubilete 718, Chapalita Sur, 45040 Zapopan, Jal. ",
    ticket:  250.0 ,
    link: " https://www.instagram.com/p/C7mulCqu9v4/ "
 }))
gusguerias.push(L.marker([ 20.664758454690695, -103.40962754847001 ], {
    nombre: " Cookie Bar ",
    direccion: "  Av Guadalupe 4041-Local 2 PB, Cd del Sol, 45040 Zapopan, Jal. ",
    ticket:  160.0 ,
    link: "https://www.instagram.com/p/C9NSFAwusuU/"
 }))
 pizzerias.push(L.marker([20.753156678945928, -103.44250276293792], {
    nombre: "Algún Lugar Pizzería",
    direccion: "Av. Base Aerea 1004-A, 45138 Zapopan, Jal.",
    ticket:  220 ,
    link: "https://www.instagram.com/p/C87t_t5uUfp/"
 }))
hotDogs.push(L.marker([20.69552061569249, -103.32612956108834], {
    nombre: "Choridogos Arnold",
    direccion: "Av. Cvln. Dr. Atl 333, Monumental, 44320 Guadalajara, Jal.",
    ticket:  130 ,
    link: "https://www.instagram.com/p/C88P2QZuh2N/"
 }))
 brunchs.push(L.marker([20.67765899593622, -103.36341000057067], {
    nombre: "Leon café y pan",
    direccion: "C. Justo Sierra 1707, Col Americana, Americana, 44600 Guadalajara, Jal.",
    ticket:  220 ,
    link: "https://www.instagram.com/p/C9AYbsMuLY6/"
 }))
 gusguerias.push(L.marker([20.756735098048406, -103.38366561207265], {
    nombre: "Crepas rafaello",
    direccion: "Av. Camino a Bosque de San Isidro 1847-L 14, Bosques del Centinela, 45133 Zapopan, Jal.",
    ticket:  100 ,
    link: "https://www.instagram.com/p/C9BZDvUOztO/"
 }))
 antojitos_Mexicanos.push(L.marker([20.722758117500504, -103.39668604066395], {
    nombre: "Ahogadas mayorga",
    direccion: "Av. Hidalgo 582, Zapopan, 45100 Zapopan, Jal.",
    ticket:  120 ,
    link: "https://www.instagram.com/p/C9DBTdNuhEV/"
 }))
 antojitos_Mexicanos.push(L.marker([20.722758117500504, -103.39668604066395], {
    nombre: "Ahogadas mayorga",
    direccion: "Av. Hidalgo 582, Zapopan, 45100 Zapopan, Jal.",
    ticket:  120 ,
    link: "https://www.instagram.com/p/C9DBTdNuhEV/"
 }))
 antojitos_Mexicanos.push(L.marker([20.722758117500504, -103.39668604066395], {
    nombre: "Ahogadas mayorga",
    direccion: "Av. Hidalgo 582, Zapopan, 45100 Zapopan, Jal.",
    ticket:  120 ,
    link: "https://www.instagram.com/p/C9DBTdNuhEV/"
 }))
 gusguerias.push(L.marker([20.681948767122414, -103.36872551756643], {
    nombre: "Friend Chicken",
    direccion: "Dentro de callejón Angulo, C. Joaquin Angulo 1473, Santa Teresita, 44600 Guadalajara, Jal.",
    ticket:  190 ,
    link: "https://www.instagram.com/p/C9F5HtIu9SH/"
 }))
 taquerias.push(L.marker([20.66801436412862, -103.31610780978019], {
    nombre: "Los Tacos de Papa",
    direccion: "44730, Basilio Vadillo 90, La Penal, 44730 Guadalajara, Jal.",
    ticket:  120 ,
    link: "https://www.instagram.com/p/C9Gijr3uM8X/"
 }))
 hamburgeserias.push(L.marker([20.640218552258297, -103.3142016469305], {
    nombre: "Burguer Chop",
    direccion: "C. Morelos 270, Centro, 45500 San Pedro Tlaquepaque, Jal.",
    ticket:  390 ,
    link: "https://www.instagram.com/p/C9QT6SdvJzM/"
 }))
 gusguerias.push(L.marker([20.696766319121092, -103.2882671549683], {
    nombre: "Tu sano pecado bionicos",
    direccion: "Calle Hacienda Santa Cruz del Valle 2431, Col Rio Verde, GDL.",
    ticket:  90 ,
    link: "https://www.instagram.com/p/C9Q2HdzOyVQ/"
 }))
 cocina_Autor.push(L.marker([20.639471917340448, -103.31262508236449], {
    nombre: "1910",
    direccion: "C. Prisciliano Sánchez 4, Centro, 45500 San Pedro Tlaquepaque, Jal.",
    ticket:  450 ,
    link: "https://www.instagram.com/p/C9S37BHOB88/"
 }))
 gusguerias.push(L.marker([20.669804967733494, -103.35276857036986], {
    nombre: "Cremeria Atemajac",
    direccion: "Av 8 de Julio 502, Zona Centro, 44100 Guadalajara, Jal",
    ticket:  90 ,
    link: "https://www.instagram.com/p/C9VAAgEO6mp/"
 }))
 hamburgeseriasYhotdogs.push(L.marker([20.665558450845168, -103.40522954095422], {
    nombre: "La Central Hamburguesería",
    direccion: "Av Guadalupe 1455, Chapalita Oriente, 45040 Zapopan, Jal.",
    ticket:  280 ,
    link: "https://www.instagram.com/p/C9WBGvOu6mW/"
 }))
 marisquerias.push(L.marker([20.738153835731474, -103.4490424512921], {
    nombre: "La brujeria",
    direccion: "Av. Aviación 4368-14, Jardín Real, 45019 Zapopan, Jal.",
    ticket:  350 ,
    link: "https://www.instagram.com/p/C9X67dvO_4f/"
 }))
 brunchs.push(L.marker([20.677993007289437, -103.36671629238224], {
    nombre: "Alltern",
    direccion: "C. Justo Sierra 1872, Ladron De Guevara, 44600 Guadalajara, Jal.",
    ticket:  250 ,
    link: "https://www.instagram.com/p/C9dLuAXOPxg/"
 }))
 hamburgeserias.push(L.marker([20.736652792672892, -103.43103426260093], {
    nombre: "Louie Burguer",
    direccion: "av. del Servidor Público 923, Valle Real, 45136 Zapopan, Jal.",
    ticket:  320 ,
    link: "https://www.instagram.com/p/C9gS3hqubSU/"
 }))
 brunchs.push(L.marker([20.6657161651132, -103.40736042102833], {
    nombre: "Artemisa ",
    direccion: "Av Guadalupe 1614-B, Chapalita, Chapalita Oriente, 45040 Zapopan, Jal.",
    ticket:  220 ,
    link: "https://www.instagram.com/p/C9h2vhEOGqV/"
 }))
 cortes_restauante.push(L.marker([20.710715137130723, -103.4123236904534], {
    nombre: "Mantela ",
    direccion: "Blvrd Puerta de Hierro 4965, 45116 Zapopan, Jal.",
    ticket:  480 ,
    link: "https://www.instagram.com/p/C9i3vDFuiV1/"
 }))
 marisquerias.push(L.marker([20.699175691830128, -103.38808595719453], {
    nombre: "Palo alto ",
    direccion: "Av Montevideo 3028, 44630 Guadalajara, Jal.",
    ticket:  390 ,
    link: "https://www.instagram.com/p/C9xx5OSOQLx/"
 }))
 brunchs.push(L.marker([20.67140902304121, -103.361051276203], {
    nombre: "Tarde o temprano",
    direccion: "Calle Argentina 365, Col Americana, Americana, 44160 Guadalajara, Jal.",
    ticket:  290 ,
    link: "https://www.instagram.com/p/C9nBlgwu1vD/"
 }))
 gusguerias.push(L.marker([20.300434727474553, -103.2561388610692], {
    nombre: "Donas Donut",
    direccion: "Carretera Ote. 39-A, 45920 Ajijic, Jal.",
    ticket:  100 ,
    link: "https://www.instagram.com/p/C9ql7TLOSxH/"
 }))
 brunchs.push(L.marker([20.673222369731, -103.37143900085171], {
    nombre: "Amelie",
    direccion: "C. Gral. San Martín 171, Col Americana, Lafayette, 44160 Guadalajara, Jal.",
    ticket:  280 ,
    link: "https://www.instagram.com/p/C9uuVFyuOnl/"
 }))
 antojitos_Mexicanos.push(L.marker([20.759210914852964, -103.43970317917702], {
    nombre: "Mama lícho",
    direccion: "Camino Viejo a Tesistan 40, Nuevo México, 45138 Zapopan, Jal.",
    ticket:  220 ,
    link: "https://www.instagram.com/p/C9z34ZQObMh/"
 }))
 antojitos_Mexicanos.push(L.marker([20.759210914852964, -103.43970317917702], {
    nombre: "Mama lícho",
    direccion: "Camino Viejo a Tesistan 40, Nuevo México, 45138 Zapopan, Jal.",
    ticket:  220 ,
    link: "https://www.instagram.com/p/C9z34ZQObMh/"
 }))
 marisquerias.push(L.marker([20.698885776486158, -103.38598311425918], {
    nombre: "Puerto 23",
    direccion: "Av Providencia 2951, Providencia 4a. Secc, 44639 Guadalajara, Jal.",
    ticket:  320 ,
    link: "https://www.instagram.com/p/C-BQ2WmuKmi/"
 }))
 taquerias.push(L.marker([20.64434225550441, -103.28827422087868], {
    nombre: "Los de bisteck con papas",
    direccion: "Av Río Nilo 3517, Jardines de Los Historiadores, 44820 Guadalajara, Jal.",
    ticket:  120 ,
    link: "https://www.instagram.com/p/C-ByL5ROn4g/"
 }))
 cortes_restauante.push(L.marker([20.642239064834616, -103.31384030543303], {
    nombre: "El Gorderón",
    direccion: "Calle Contreras Medellín 167, San Juan, 45500 San Pedro Tlaquepaque, Jal.",
    ticket:  320 ,
    link: "https://www.instagram.com/p/C-GYqqBuKPy/"
 }))
 cortes_restauante.push(L.marker([ 20.726969918417716, -103.4158354464608 ], {
    nombre: ' Cortes selectos republica ',
    direccion: ' Anillo Perif. Nte. Manuel Gómez Morín 736, Santa Margarita1a Secc., 45140 Zapopan, Jal. ',
    ticket: 300,
    link: ' https://www.instagram.com/p/C-I9Xo5OjVH/ '
}));
brunchs.push(L.marker([ 20.690617255761577, -103.38200585239494 ], {
    nombre: ' Jardin Rochelle ',
    direccion: ' C. José María Vigil 2681, Lomas de Guevara, 44657 Guadalajara, Jal. ',
    ticket: 280.0 ,
    link: ' https://www.instagram.com/p/C-LCJzzO957/ '
}));
nieves.push(L.marker([ 20.712413098349366, -103.37826997325179 ], {
    nombre: ' Garrafaland ',
    direccion: ' Plaza Patria Local B4, Guadalajara, Jalisco ',
    ticket: 120,
    link: ' https://www.instagram.com/p/C-OGoSyOBm6/ '
}));
hamburgeserias.push(L.marker([ 20.621231787770085, -103.28738444710794 ], {
    nombre: ' Barrio Chicken MX ',
    direccion: ' Carlos Salgado 6374, Nueva Central Camionera, 45580 San Pedro Tlaquepaque, Jal. ',
    ticket: 220,
    link: ' https://www.instagram.com/p/C-TzxbiO_Ye/ '
}));
cortes_restauante.push(L.marker([ 20.673163478769418, -103.3768194665619 ], {
    nombre: ' Mutante restaurante GDL ',
    direccion: ' Lopez Cotilla 1937 Colonia Americana Laffayette S.J, 44160 Guadalajara, Jal. ',
    ticket: 450,
    link: ' https://www.instagram.com/p/C-V1P2MuZjP/ '
}));
taquerias.push(L.marker([ 20.66296351296687, -103.43110096269365 ], {
    nombre: ' Tacos fermin ',
    direccion: ' Rafael Sanzio 721, Zapopan. ',
    ticket: 150,
    link: ' https://www.instagram.com/p/C-WX0p0OtlW/ '
}));
cocina_Inglesa.push(L.marker([ 20.67397087437878, -103.37757472774099 ], {
    nombre: ' Chippy-T Fish and Chips ',
    direccion: ' C. Francisco Javier Gamboa 72a, Arcos Vallarta, 44140 Guadalajara, Jal. ',
    ticket: 280,
    link: ' https://www.instagram.com/p/C-YbUHGOQHO/ '
}));
antojitos_Mexicanos.push(L.marker([ 20.659619753700554, -103.44582396945569 ], {
    nombre: ' Manteca Cocina ',
    direccion: ' Guadalupe 6818, Zapopan. ',
    ticket: 290,
    link: ' https://www.instagram.com/p/C-bkAibuIPv/ '
}));

antojitos_Mexicanos.push(L.marker([ 20.691988484240692, -103.33317061086893 ], {
    nombre: ' Tortas ahogadas Guicho ',
    direccion: ' Calz. Independencia Norte 1105, Oblatos Pte., 44290 Guadalajara, Jal. ',
    ticket: 120,
    link: ' https://www.instagram.com/p/C-f3kmfug7J/ '
}));
marisquerias.push(L.marker([ 20.666419292077755, -103.42456570308268 ], {
    nombre: ' Restaurante Save ',
    direccion: ' Av. Patria 574, Jardines de Guadalupe, 44030 Zapopan, Jal. ',
    ticket: 350,
    link: ' https://www.instagram.com/p/C-ipLvzOtdE/ '
}));
brunchs.push(L.marker([ 20.67881181817855, -103.36567422690753 ], {
    nombre: ' Caligari ',
    direccion: ' Juan Manuel 1406, Col Americana, Santa Teresita, 44600 Guadalajara, Jal. ',
    ticket: 280,
    link: ' https://www.instagram.com/p/C-kzoXeuF1u/ '
}));

antojitos_Mexicanos.push(L.marker([ 20.673014569936797, -103.3623000786908 ], {
    nombre: ' Baudelaire fonda ',
    direccion: '  C. Emeterio Robles Gil 239, Col Americana, 44160 Guadalajara, Jal. ',
    ticket: 250,
    link: ' https://www.instagram.com/p/C-shlVPOipW/ '
}));
pizzerias.push(L.marker([ 20.65873785038257, -103.44320565482728 ], {
    nombre: ' Yo amo la pizza ',
    direccion: ' Av Guadalupe 6473, Chapalita Inn, Exitmex, 45010 Guadalajara, Jal. ',
    ticket: 120,
    link: ' https://www.instagram.com/p/C-s_uCpudYM/ '
}));
cortes_restauante.push(L.marker([ 20.702185106017286, -103.37883384265086 ], {
    nombre: ' Dubino ',
    direccion: ' Av Providencia 2469, Providencia, 44630 Guadalajara, Jal.  ',
    ticket: 350,
    link: ' https://www.instagram.com/p/C-vlUv2uwzN/ '
}));
taquerias.push(L.marker([ 20.674730339558845, -103.4161053897784 ], {
    nombre: '  Tacos El Socio ',
    direccion: ' Av Manuel J. Clouthier 141-A, Prados Vallarta, 45020 Zapopan, Jal. ',
    ticket: 120,
    link: ' https://www.instagram.com/p/C-wIXj-OdbZ/ '
}));
taquerias.push(L.marker([ 20.714426359651707, -103.39286634533018 ], {
    nombre: ' Tacos Santa Fe del Güero ',
    direccion: ' Calle Eva Briseño 593, Santa Fe, 45168 Zapopan, Jal. ',
    ticket: 140,
    link: ' https://www.instagram.com/p/C-1RM1KOL2X/ '
}));
gusguerias.push(L.marker([ 20.66370562133093, -103.41493121366435 ], {
    nombre: ' Frutas locas genaro ',
    direccion: ' C. Organización 811 C, Cd de los Niños, 45040 Zapopan, Jal ',
    ticket: 140,
    link: ' https://www.instagram.com/p/C_BgLLXuqar/ '
}));

brunchs.push(L.marker([ 20.694366153823033, -103.38609056745199 ], {
    nombre: ' Dolce Bisquet Gdl ',
    direccion: '  Av Terranova 1170, entre Turín y Otranto, Lomas de Providencia, 44647 Guadalajara, Jal. ',
    ticket: 260,
    link: ' https://www.instagram.com/p/C_I13roO4_w/ '
}));

marisquerias.push(L.marker([ 20.675740018223205, -103.42545464619303 ], {
    nombre: "Los Pp's",
    direccion: ' Av. Miguel Angel 128, Real Vallarta, 45020 Zapopan, Jal. ',
    ticket: 320,
    link: ' https://www.instagram.com/p/C_RDKEpxyPa/ '
}));
taquerias.push(L.marker([ 20.66513232641488, -103.36234758418746 ], {
    nombre: ' Barbacoa El Torito ',
    direccion: ' Calle Argentina 761, Moderna, 44190 Guadalajara, Jal. ',
    ticket: 120,
    link: ' https://www.instagram.com/p/C_TJV9jurg-/ '
}));
hamburgeserias.push(L.marker([ 20.71451457012933, -103.37819641341117 ], {
    nombre: ' Carl’s Jr. ',
    direccion: ' Ávila Camacho 2958, Guadalajara. ',
    ticket: 250,
    link: ' https://www.instagram.com/p/C_UJ7TkuE-x/ '
}));
cocina_Autor.push(L.marker([ 19.425126685062462, -99.20329607310565 ], {
    nombre: ' Carmela y sal ',
    direccion: ' Torre Virreyes, Pedregal 24, Lomas - Virreyes, 11040 Ciudad de México, CDMX ',
    ticket: 500.0,
    link: ' https://www.instagram.com/p/C_WvVKSunFj/ '
}));
gusguerias.push(L.marker([ 20.71192275412976, -103.38013848461053 ], {
    nombre: ' City Market Guadalajara ',
    direccion: '  Plaza Patria, Av de la Patria 1950, Plaza Patria, 45160 Guadalajara, Jal. ',
    ticket: 130,
    link: ' https://www.instagram.com/p/C_ZJzNbuM8w/ '
}));
brunchs.push(L.marker([ 20.640192383964305, -103.41585350583101 ], {
    nombre: ' Mmm Qué Yico ',
    direccion: '  Av. Mariano Otero 3525, La Calma, 45080 Zapopan, Jal. ',
    ticket: 180,
    link: ' https://www.instagram.com/p/C_a3ZzUuHpd/ '
}));
taquerias.push(L.marker([ 20.675938773929314, -103.38775150356912 ], {
    nombre: ' Shufy Shufy ',
    direccion: ' C. Fernando De Celada 2820, Vallarta Nte., 44690 Guadalajara, Jal. ',
    ticket: 150,
    link: ' https://www.instagram.com/p/C_b6U9qOHx5/ '
}));
hotDogs.push(L.marker([ 20.737430236257694, -103.43095560229516 ], {
    nombre: ' Los más vega ',
    direccion: ' Av. del Servidor Público 2324, Valle Real, 45136 Zapopan, Jal. ',
    ticket: 220,
    link: ' https://www.instagram.com/p/C_ec2vhuwQ7/ '
}));

